import Simple from "../components/Navbar"; 
import { useMediaQuery } from "react-responsive";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input
  } from '@chakra-ui/react'
import { Container , Grid, GridItem ,Card,CardBody, Box,Tab,TabIndicator,TabPanels,TabPanel,Tabs,TabList, Image, Button, HStack,Text, VStack} from "@chakra-ui/react";
import './stylesheets/listingdescription.css'
import './stylesheets/sellerdashboard.css'
import GreenRect from '../assets/Icons/green-rect.svg'
import GreyRect from '../assets/Icons/grey-rect.svg'
import Calendar from '../assets/Icons/date.svg'
import TeamSize from '../assets/Icons/team.svg'
import Dollar from '../assets/Icons/dollar.svg'
import Customers from '../assets/Icons/customers.svg'
import Blocker from '../assets/blocker.svg'
import { useDisclosure } from "@chakra-ui/react";
import uploadLogo from '../assets/uploadlogo.svg'
import EditModal from "../components/modeloverlays/EditMetricsOverlay";
const SellerDashboard = ()=>{
    const { isOpen, onOpen, onClose } = useDisclosure()
const isMobile = useMediaQuery({maxWidth:1067})
const MetricsEditgridProps = isMobile
? {
    display: 'flex',
    flexDirection: 'column',
  }
: {};
return(
    <>
    <Simple userName="{user.displayName}" isLoggedin={false}avatarUrl={"'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"}/>

        
        <Container maxW={'7xl'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
       
            <div className="listings-card-container">
                <Card>
                    <CardBody backgroundColor={"#EAFBFF"}  borderRadius={'2%'}>
                        <div className="card-inside-container-test">
                            <div className="company-category-container-dsbrd">
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} padding={'15%'} border={'2px'}  borderRadius={'5px'} borderColor={'#79838B'}>
                                    <Box padding={'10%'}>
                                        <Image src={uploadLogo}></Image>
                                        
                                    </Box>
                                    <Box margin={'2%'} >
                                        <Button size={'sm'} bo backrderRadius={'2px'}  border={'2px'} borderColor={'#055566'} backgroundColor={'#EAFBFF'}>upload logo</Button>
                                    </Box>
                                    
                                </Box>
                                <div className="legend">
                                    <HStack margin={'2px'}>
                                        <Box display={'flex'} >
                                            <Image src={GreenRect}></Image>
                                        </Box>
                                        <Text color={"#055566"} fontSize={'x-small'} fontWeight={'semibold'}>WILL BE LIVE</Text>
                                        
                                    </HStack>
                                    <HStack  margin={'2px'}>
                                        <Box display={'flex'} >
                                            <Image src={GreyRect}></Image>
                                        </Box>
                                        <Text fontWeight={'semibold'} fontSize={'x-small'}>HIDDEN</Text>
                                        
                                    </HStack>

                                    
                                </div>
                            </div>
                            <Grid
                            width={'100%'}
  
                                templateRows='repeat(4, 1fr)'
                                templateColumns='repeat(4, 1fr)'
                                
                                >
                            <GridItem colSpan={4} justifyContent={'center'} 
                            flexDirection={'column'}>
                                <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)'  >
                                    <GridItem colSpan={1}    display={'flex'}  fontFamily="'Poppins', sans-serif" fontSize={'150%'} fontWeight={'bold'} color={'#055566'} alignItems={'center'} justifyContent={'center'} > ippoPay ltd</GridItem>
                                    <GridItem  colStart={4} colEnd={5} display={'flex'}  fontFamily="'Poppins', sans-serif" fontSize={'3xl'} fontWeight={'bold'} color={'#055566'} alignItems={'center'} justifyContent={'center'}    >$140,000</GridItem>
                                </Grid>
                            </GridItem>
                            <GridItem colSpan={1}  >
                                <VStack >
                                    <Text className="metrics-header"> DATE FOUNDED</Text>
                                    <HStack >
                                        <Box><Image src={Calendar}/></Box>
                                        <Text className="metrics-desc"> March 2023</Text>
                                    </HStack>
                                </VStack>
                            </GridItem>
                            <GridItem colSpan={1} >
                            <VStack >
                                    <Text className="metrics-header"> TEAM SIZE</Text>
                                    <HStack >
                                        <Box><Image src={TeamSize}/></Box>
                                        <Text className="metrics-desc"> 4</Text>
                                    </HStack>
                                </VStack>
                            </GridItem>
                            <GridItem colSpan={1} >
                                <VStack >
                                    <Text className="metrics-header">TTM REVENUE</Text>
                                    <HStack >
                                        <Box><Image src={Calendar}/></Box>
                                        <Text className="metrics-desc">$255,000</Text>
                                    </HStack>
                                </VStack></GridItem>
                            <GridItem colSpan={1} >
                            <VStack >
                                    <Text className="metrics-header"> CUSTOMERS</Text>
                                    <HStack >
                                        <Box><Image src={Customers}/></Box>
                                        <Text className="metrics-desc">10,000</Text>
                                    </HStack>
                                </VStack>
                            </GridItem>
                                <GridItem colSpan={1} >
                                <VStack >
                                    <Text className="metrics-header"> LOCATION</Text>
                                    <HStack >
                                        
                                        <Text className="metrics-header" fontSize={'sm'} fontWeight={'medium'}> 200 st, Toronto, Canada</Text>
                                    </HStack>
                                </VStack>
                                </GridItem>
                            <GridItem colSpan={1}  >
                            <VStack >
                                    <Text className="metrics-header"> LM REVENUE</Text>
                                    <HStack >
                                        
                                        <Text className="metrics-header" fontSize={'sm'} fontWeight={'medium'}> $455,000</Text>
                                    </HStack>
                                </VStack>
                            </GridItem>
                            <GridItem colSpan={1} ><VStack >
                                    <Text className="metrics-header">CUSTOMER TYPE</Text>
                                    <HStack >
                                        
                                        <Text className="metrics-header" fontSize={'sm'} fontWeight={'medium'}> B2B</Text>
                                    </HStack>
                                </VStack></GridItem>
                            <GridItem colSpan={1}  >
                            <VStack >
                                    <Text className="metrics-header"> URL</Text>
                                    <HStack >
                                        
                                        <Text className="metrics-header" fontSize={'sm'} fontWeight={'medium'}> www.berikopaandi.com</Text>
                                    </HStack>
                                </VStack>
                            </GridItem>
                            <GridItem colSpan={4} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                <EditModal
                                     title={<div className="page-headers"><p className="page-headers-p"><span>Edit your metrics here</span></p></div>}
                                     buttonText="Edit"
                                     >
                                        
                                    <FormControl padding={'2%'} backgroundColor={'#eafbff'} borderRadius={'2%'} overflow="scroll">
                                    <div className="page-headers"><p className="page-headers-p" ><span>Overview</span></p></div>
                                        <div className="overlay-form-input">
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>Name</FormLabel>
                                        <Input border="2px solid #6E777F" type='text' placeholder='Ippopay, Ltd'  />
                                        </div>
                                        
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>Asking Price</FormLabel>
                                        <Input  border="2px solid #6E777F" type='number' placeholder={140000} />
                                        <FormHelperText color={'#79838B'}></FormHelperText>
                                        </div>
                                        </div>

                                        <div className="overlay-form-input">
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>URL </FormLabel>
                                        <Input  border="2px solid #6E777F" type='url' placeholder='WWW.BERIKOPAANDI.COM'  />
                                        </div>
                                        
                                        <div className="overlay-form-input-child">
                                        <FormLabel  fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>Date founded</FormLabel>
                                        <Input type='date'  border="2px solid #6E777F" placeholder='Ippopay, Ltd'  />
                                        </div>
                                        
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontWeight={'bold'}>TTM Revenue</FormLabel>
                                        <Input  border="2px solid #6E777F" type='number' placeholder={125000} />
                                        <FormHelperText color={'#79838B'}></FormHelperText>
                                        </div>
                                        </div>

                                        <div className="overlay-form-input">
                                        <div className="overlay-form-input-child">
                                        <FormLabel>Customer type</FormLabel>
                                        <Input type='' placeholder='Ippopay, Ltd'  />
                                        </div>
                                        
                                        <div className="overlay-form-input-child">
                                        <FormLabel>Asking Price</FormLabel>
                                        <Input type='number' />
                                        <FormHelperText color={'#79838B'}>Write a valuable amount,Your Startup deserves it.</FormHelperText>
                                        </div>
                                        </div>
                                    </FormControl>
                                </EditModal>
                                
                            </GridItem>
    
  
                            </Grid>

                        </div>
                    </CardBody>
                </Card>

                
            </div>
            <div className="listings-card-container">
                <Card  backgroundColor={"#EAFBFF"}>
                <CardBody>
                <Tabs position="relative" variant={isMobile?'enclosed':'unstyled'}>
                <TabList  style={isMobile?{ overflow:  'scroll', scrollbarWidth: 'none','-ms-overflow-style': 'none'}:{overflow:  'hidden'}}>
                    <Tab style={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={'800'}>DESCRIPTION</Tab>
                    <Tab style={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={'800'}>TECH STACK</Tab>
                    <Tab style={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={'800'}>REASON FOR ASKING PRICE</Tab>
                </TabList>
                {!isMobile && (
                    <TabIndicator
                        mt="-1.5px"
                        height="5px"
                        bg="#055566"
                        borderRadius="5px"
                        color={'#055566'}
                    />
                )}
                <TabPanels>
                <TabPanel>
                    <p> This $500K TTM GreenTech company for sale sells sUAV's (drones) with a specialty sensor (differentiator) that measures the emission rates of gases. Operators not only discovers leaks, but they can actually measure them. This a huge improvement over existing ground LiDAR solutions because it allows operators to quickly & safely asses a situation with regards to both safety of people and the environment. This is also a "right-timing opportunity" because regulators are now moving towards requiring the measurement of emissions for this very reason. The company as a remote friendly business currently located in Canada. Its most recent deal is a 12 Month $370K USD contract for 4 sites across America. As of Apr '23 they also have an additional $125k in the sales pipe (late stage negotiation). Projected revenue ’23: $600-700K Assets: $100K in the bank, $100K roughly in inventory The drones are purchased (scalable) and mounted with sensors that feeds data to their custom built software. Customers are supplied with all the necessary equipment, SOP (Standard Operating Procedures) to fly, and the software to make sense of it all. Training and onboarding is an addition source of revenue. The business is ready to scale so an ideal acquirer would be someone with a hunger for sales & marketing. Deep knowledge of hardware or software is not required as the owner is happy to support the new buyer, and is also open to selling a controlling stake and working with the new owner going forward.</p>
                </TabPanel>
                <TabPanel>
                    <p>two!</p>
                    <p>two!</p>
                    <p>two!</p>
                    <p>two!</p>
                    <p>two!</p>
                </TabPanel>
                <TabPanel>
                    <div className="edit-tab">
                        <p>This $500K TTM GreenTech company for sale sells sUAV's (drones) with a specialty sensor (differentiator) that measures the emission rates of gases. Operators not only discovers leaks, but they can actually measure them. This a huge improvement over existing ground LiDAR solutions because it allows operators to quickly & safely asses a situation with regards to both safety of people and the environment. This is also a "right-timing opportunity" because regulators are now moving towards requiring the measurement of emissions for this very reason. The company as a remote friendly business currently located in Canada. Its most recent deal is a 12 Month $370K USD contract for 4 sites across America. As of Apr '23 they also have an additional $125k in the sales pipe (late stage negotiation). Projected revenue ’23: $600-700K Assets: $100K in the bank, $100K roughly in inventory The drones are purchased (scalable) and mounted with sensors that feeds data to their custom built software. Customers are supplied with all the necessary equipment, SOP (Standard Operating Procedures) to fly, and the software to make sense of it all. Training and onboarding is an addition source of revenue. The business is ready to scale so an ideal acquirer would be someone with a hunger for sales & marketing. Deep knowledge of hardware or software is not required as the owner is happy to support the new buyer, and is also open to selling a controlling stake and working with the new owner going forward.</p>

                    </div>
                </TabPanel>
                </TabPanels>
                </Tabs>
                <div className="edit-tab">
                <EditModal
                                     title={<div className="page-headers"><p className="page-headers-p"><span>Financial Forum</span></p></div>}
                                     buttonText="Edit"
                                     >
                                        
                                    <FormControl padding={'2%'} backgroundColor={'#eafbff'} borderRadius={'2%'}>
                                    <div className="page-headers"><p className="page-headers-p" ><span>Overview</span></p></div>
                                        <div className="overlay-form-input">
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>Description</FormLabel>
                                        <Input type='text' size={'lg'} placeholder='Give a sweet summary'  />
                                        </div>
                                        
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>Tech stack</FormLabel>
                                        <Input  size={'lg'} type='text' />
                                        <FormHelperText color={'#79838B'}></FormHelperText>
                                        </div>
                                        </div>

                                        <div className="overlay-form-input">
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>Reason for listing </FormLabel>
                                        <Input type='text'   />
                                        </div>
                                        
                                       
                                        </div>

                                        

                                      
                                        
                                    </FormControl>
                                </EditModal>
                </div>

                </CardBody>
                </Card>

                
            </div>
            <div className="listings-card-container">
                <Card backgroundColor={"#EAFBFF"}>
                    <CardBody>
                        <div className="edit-metrics-section-container">
                            <div className="page-headers"><p className="page-headers-p"><span>Edit your metrics here</span></p></div>
                                <div className="edit-metrics-section" >
                                <Grid templateColumns='repeat(5, 1fr)' gap={5} padding={'2%'} {...MetricsEditgridProps} >
                                    <GridItem w='100%' h='10'>
                                        <HStack display={'flex'}  alignItems={'center'} justifyContent={'center'}>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#055566'}>TTM REVENUE</Text>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#6D767E'}>$120,000</Text>
                                        </HStack>
                                    </GridItem>
                                    <GridItem w='100%' h='10'  ><HStack display={'flex'}  alignItems={'center'} justifyContent={'center'}>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#055566'}>TTM PROFIT</Text>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#6D767E'}>$120,000</Text>
                                        </HStack>
                                    </GridItem>
                                    <GridItem w='100%' h='10'>
                                    <HStack display={'flex'}  alignItems={'center'} justifyContent={'center'}>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#055566'}>LM REVENUE</Text>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#6D767E'}>$120,000</Text>
                                        </HStack>
                                    </GridItem>
                                    <GridItem w='100%' h='10' >
                                    <HStack display={'flex'}  alignItems={'center'} justifyContent={'center'}>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#055566'}>LM Profit</Text>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#6D767E'}>$120,000</Text>
                                        </HStack>
                                    </GridItem>
                                    <GridItem w='100%' h='10' >
                                    <HStack display={'flex'}  alignItems={'center'} justifyContent={'center'}>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#055566'}>TTM REVENUE</Text>
                                            <Text fontFamily={"'Poppins', sans-serif"} fontWeight={'bold'} color={'#6D767E'}>$120,000</Text>
                                        </HStack>
                                    </GridItem>
                                </Grid>
                                <div className="edit-tab">
                <Button backgroundColor={'#055566'} color={'white'}>Edit</Button>
                </div>
                                </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <div className="listings-card-container">
                <Card backgroundColor={"#EAFBFF"}>
                    <CardBody>
                    <div className="page-headers"><p className="page-headers-p"><span>Edit your metrics</span></p></div>
                    <div className="edit-tab">
                    <EditModal
                                     title={<div className="page-headers"><p className="page-headers-p"><span>Edit your metrics</span></p></div>}
                                     buttonText="Edit"
                                     >
                                        
                                    <FormControl padding={'2%'} backgroundColor={'#eafbff'} borderRadius={'2%'} overflow="scroll">
                                    <div className="page-headers"><p className="page-headers-p" ><span>Overview</span></p></div>
                                        <div className="overlay-form-input">
                                        <div className="overlay-form-input-child">
                                        <FormLabel fontFamily={"'poppins',sans-serif"} fontWeight={'bold'}>Upload here</FormLabel>
                                        <Input type='file' size={'lg'}   />
                                        </div>
                                        
                                       
                                        </div>

                                        

                                        

                                      
                                        
                                    </FormControl>
                                </EditModal>
                    </div>
                    </CardBody>
                </Card>
            </div>

            <div className="listings-card-container">
                <Card backgroundColor={"#EAFBFF"}>
                    <CardBody>
                    <div className="page-headers"><p className="page-archive-headers-p"><span>Danger zone</span></p></div>
                    <div className="achive-this-listing">
                    <div className="edit-tab">
                        <button  className="a-button">Archive this listing</button>
                        
                    </div>
                    <div className="edit-tab">
                        <button  className="a-button">Delete this Listing</button>
                        
                    </div>
                    </div>
                    </CardBody>
                </Card>
            </div>


        

    
            </Container>
    </>
    
)
}

export default SellerDashboard;