import * as React from "react";
const CartSVGComponent = (props) => (
  <svg
    
    width="32px"
    height="32px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3,22H21a1,1,0,0,0,.994-1.11l-2-18A1,1,0,0,0,19,2H5a1,1,0,0,0-.994.89l-1.556,14-.444,4A1,1,0,0,0,3,22ZM5.9,4H18.1l1.334,12H4.562ZM4.34,18H19.66l.223,2H4.117ZM16,7V9A4,4,0,0,1,8,9V7a1,1,0,0,1,2,0V9a2,2,0,0,0,4,0V7a1,1,0,0,1,2,0Z" />
  </svg>
);
export default CartSVGComponent;
