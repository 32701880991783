//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~React~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import { useState } from "react";
//`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Chakra UI~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import Simple from "../components/Navbar";
import { FormControl,Input } from "@chakra-ui/react";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`Reusable react components~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import HeroSection from "../components/sections/HeroSection";
import ForgotPasswordCard from "../components/ForgotPassword";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Stylesheets~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~```
import "./stylesheets/forgotpassword.css"
const forgotPassword = ()=>{
    return(
        <>
        <Simple userName="{user.displayName}" isLandingpage={true} isLoggedin={false}  avatarUrl={"'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"}/>
        <div className="hero-section">
            <div className="hero-content">
                <div className="hero-text">
                    <p>Recovery Made Easy</p>

                </div>
                <div style={{padding:'2%', margin:'2%', width:'50%'}}>
                    <ForgotPasswordCard/>
                </div>
            </div>

        </div>
        </>
    )
}
export default forgotPassword