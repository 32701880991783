import React, { useState, useEffect } from "react";
import "./sellerform.css";
import { Button, Radio, RadioGroup, Stack } from "@chakra-ui/react";
function Sellerformtwo({ onDataUpdate, data, step, onFormChange }) {
  const [value, setValue] = useState(data.value || "1");
  const [twelverevenue, settwelverevenue] = useState(data.twelverevenue || "");
  const [lastmonthrevenue, setlastmonthrevenue] = useState(
    data.lastmonthrevenue || ""
  );
  const [overallrevenue, setoverallrevenue] = useState(
    data.overallrevenue || ""
  );
  const [overallnetprofit, setoverallnetprofit] = useState(
    data.overallnetprofit || ""
  );
  const [twelvenetprofit, settwelvenetprofit] = useState(
    data.twelvenetprofit || ""
  );
  const [acquistiontype, setacquistiontype] = useState(
    data.acquistiontype || ""
  );
  const backfunc = () => {
    onDataUpdate(1);
  };
  const updateData = () => {
    onDataUpdate(3);
    const updatedData = {
      value,
      twelverevenue,
      lastmonthrevenue,
      overallrevenue,
      overallnetprofit,
      twelvenetprofit,
      acquistiontype,
    };
    onFormChange(step, updatedData);
  };

  return (
    <div>
      <div className="horizontal-conatiner">
        <h1>Revenue Status?</h1>
        <RadioGroup onChange={setValue} value={value}>
          <Radio
            value="1"
            borderColor={"#77898F"}
            _checked={{
              bg: "#055566",
              borderColor: "#055566",
            }}
          >
            <h1>YES</h1>
          </Radio>
          <Radio
            value="2"
            borderColor={"#77898F"}
            _checked={{
              bg: "#055566",
              borderColor: "#055566",
            }}
          >
            <h1>NO</h1>
          </Radio>
        </RadioGroup>
      </div>
      <div className="sellerfrom-form">
        <div style={{ "max-width": "300px" }}>
          <input
            type="text"
            id="twelverevenue"
            name="twelverevenue"
            placeholder="12 Months Revenue"
            onChange={(e) => settwelverevenue(e.target.value)}
            value={twelverevenue}
            disabled={value == 2 && true}
            required
          />
          <input
            type="text"
            id="lastmonthrevenue"
            name="lastmonthrevenue"
            placeholder="Last Month Revenue"
            onChange={(e) => setlastmonthrevenue(e.target.value)}
            value={lastmonthrevenue}
            disabled={value == 2 && true}
            required
          />
          <input
            type="text"
            id="overallrevenue"
            name="overallrevenue"
            placeholder="Overall Revenue"
            onChange={(e) => setoverallrevenue(e.target.value)}
            value={overallrevenue}
            disabled={value == 2 && true}
            required
          />
        </div>
        <div style={{ "max-width": "300px" }}>
          <input
            type="text"
            id="overallnetprofit"
            name="overallnetprofit"
            placeholder="Overall Net Profit"
            onChange={(e) => setoverallnetprofit(e.target.value)}
            value={overallnetprofit}
            disabled={value == 2 && true}
            required
          />
          <input
            type="text"
            id="twelvenetprofit"
            name="twelvenetprofit"
            placeholder="12 Months Net Profit"
            onChange={(e) => settwelvenetprofit(e.target.value)}
            value={twelvenetprofit}
            disabled={value == 2 && true}
            required
          />
          <input
            type="text"
            id="acquistiontype"
            name="acquistiontype"
            placeholder="Acquisition type"
            onChange={(e) => setacquistiontype(e.target.value)}
            value={acquistiontype}
            disabled={value == 2 && true}
            required
          />
          <Button
            onClick={updateData}
            _hover={{ background: "#003f4c" }}
            _active={{ background: "#002835" }}
          >
            NEXT
          </Button>
          <Button
            style={{ position: "absolute", left: "0" }}
            onClick={backfunc}
            _hover={{ background: "#003f4c" }}
            _active={{ background: "#002835" }}
          >
            BACK
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Sellerformtwo;
