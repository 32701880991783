import '../stylesheet/testimonials.css'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css';
import { Avatar } from '@chakra-ui/react';


const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2 },

};

const items = [
    <div className="item" data-value="1">
        <div className="card">
            
            <div className="card-container">
                <div className="main-content">Comfortable and met all my expectations! I ordered a medium and it fit perfectly</div>
                <div className="full-review">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lacus, auctor pretium ac ultrices. Dui lacus dignissim tincidunt urna, at enim tempo. Pellentesque amet Lorem ipsum dolor sit amet, </div>
                <Avatar
                  size={'md'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <div className="user-name">Kondaraasu</div>
                <div className="company-name">Nallammanayakkar foundations</div>
                
            </div>
        </div>
    </div>,
    <div className="item" data-value="2">
         <div className="card">
            
         <div className="card-container">
                <div className="main-content">Comfortable and met all my expectations! I ordered a medium and it fit perfectly</div>
                <div className="full-review">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lacus, auctor pretium ac ultrices. Dui lacus dignissim tincidunt urna, at enim tempo. Pellentesque amet Lorem ipsum dolor sit amet, </div>
                <Avatar
                  size={'md'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <div className="user-name">Kondaraasu</div>
                <div className="company-name">Nallammanayakkar foundations</div>
                
            </div>
        </div>
    </div>,
    <div className="item" data-value="3">
        <div className="card">
            
        <div className="card-container">
                <div className="main-content">Comfortable and met all my expectations! I ordered a medium and it fit perfectly</div>
                <div className="full-review">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lacus, auctor pretium ac ultrices. Dui lacus dignissim tincidunt urna, at enim tempo. Pellentesque amet Lorem ipsum dolor sit amet, </div>
                <Avatar
                  size={'md'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <div className="user-name">Kondaraasu</div>
                <div className="company-name">Nallammanayakkar foundations</div>
                
            </div>
        </div>
    </div>,
    <div className="item" data-value="4">
        <div className="card">
            
        <div className="card-container">
                <div className="main-content">Comfortable and met all my expectations! I ordered a medium and it fit perfectly</div>
                <div className="full-review">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lacus, auctor pretium ac ultrices. Dui lacus dignissim tincidunt urna, at enim tempo. Pellentesque amet Lorem ipsum dolor sit amet, </div>
                <Avatar
                  size={'md'}
                  src={
                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <div className="user-name">Kondaraasu</div>
                <div className="company-name">Nallammanayakkar foundations</div>
                
            </div>
        </div>
    </div>,
    <div className="item" data-value="5">5</div>,
];
const Testimonials = ()=>{
    <>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@700&display=swap"
      rel="stylesheet"
    />
  </>

    return(
        <section className="testimonial-section">
            <div className="section-header">What people say about us</div>
            <AliceCarousel
                mouseTracking
                items={items}
                paddingLeft={50}
                
                paddingRight={50}
                responsive={responsive}
            />
        </section>
    )
}
export default Testimonials