import React, { createContext, useContext, useEffect, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import axios from "axios";
const User = createContext();

const Context = ({ children }) => {
  const [user, setUser] = useState(null);
  const toast = useToast();
  useEffect(() => {
    const getUser = async () => {
      try {
        const config = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
          withCredentials: true,
        };
        const borl = await axios.get("/api/auth/login/success", config);
        setUser(borl.data.user);
      } catch (error) {
        toast({
          title: "Error Occured!",
          description: error.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    };
    getUser();
  }, []);
  console.log(user);
  return <User.Provider value={{ user, setUser }}>{children}</User.Provider>;
};
export const UserState = () => {
  return useContext(User);
};
export default Context;
