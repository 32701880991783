import { useEffect, useState } from "react";
import { UserState } from "../Context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~SChakra ui~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~``
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Button, Image, FormControl, FormLabel, Input, Flex, Stack, Link } from '@chakra-ui/react'
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Reusable react components~~~~~~~~~~~~~~~~~~~~~~~~~
import Simple from "./Navbar";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Stylesheets~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import "./stylesheet/profile.css"
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Assets~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import userProfile from "../assets/user.png"

const Profile = ()=>{
    const {user, setUser} = UserState()

    const [displayName, setDisplayName] = useState(user?user.displayName || user.email:"please wait")
    const [organisation, setOrganisation] = useState('Beriko Inc')
    const [LinkedInUrl, setLinkedInUrl] = useState('LinkedIn url')
    
    const router = useHistory()
    console.log("User is",user)
    const HandleDisplayNameChange = (event)=>{
        setDisplayName(event.target.value)

    }
    const HandleOrganisationChange = (event)=>{
        setOrganisation(event.target.value)

    }
    const HandleLinkedInUrlChange = (event)=>{
        setLinkedInUrl(event.target.value)

    }
    useEffect(()=>{
        const checkAuthenticity = ()=>{
            if(!user){
                
                router.push('/login')
            }
        }

        checkAuthenticity()

    }, [])
    const MAX_DISPLAY_NAME_LENGTH = 20; // Set your maximum length for display name
const MAX_ORGANISATION_LENGTH = 50; // Set your maximum length for organisation
const MAX_LINKEDIN_URL_LENGTH = 100; // Set your maximum length for LinkedIn URL
    return(
        <>
            {
                user?(
                <div className="page-container">
                    <div className="profile-container">
                        <Tabs align='center' width={'100%'}>
                            <TabList>
                                <Tab _selected={{ fontWeight:'900',  }} style={{fontFamily:"'Poppins', sans-serif", fontWeight:'800', color:'#6D767E'}}>PROFILE</Tab>
                                <Tab _selected={{ color: 'blue',  }}style={{fontFamily:"'Poppins', sans-serif", fontWeight:'800',color:'#6D767E'}}>PERSONAL INFO</Tab>
                            </TabList>
                            <TabIndicator
                                mt="-1.5px"
                                height="2px"
                                bg="blue.500"
                                borderRadius="1px"
                                />
                            <TabPanels>
                                <TabPanel>
                                    <div style={{display:'flex', flexDirection:'column', background:'#F3FBFB', margin:'2%', padding:'2%'}}>
                                        <div style={{display:'flex' , borderRadius:'2%', justifyContent:'space-around'}}>
                                            
                                                <div className="identity">
                                                <Image
                                                    borderRadius='full'
                                                    boxSize='140px'
                                                    src={userProfile}
                                                    //alt='Dan Abramov'
                                                    padding='5%'
                                                />
                                            
                                                <p style={{fontFamily:"'Poppins', sans-serif", fontWeight:'800', color:'#055566', fontSize:'150%', width:'60%',overflow:'hidden', whiteSpace:'nowrap', textOverflow:"ellipsis"}}> {displayName.length > MAX_DISPLAY_NAME_LENGTH
          ? `${displayName.substring(0, MAX_DISPLAY_NAME_LENGTH)}...`
          : displayName}</p>
                                                <p style={{fontFamily:"'Poppins', sans-serif", fontWeight:'500', color:'#055566', fontSize:'100%',textAlign:'center', width:'45%',overflow:'hidden', whiteSpace:'nowrap', textOverflow:"ellipsis"}}> {organisation.length > MAX_ORGANISATION_LENGTH
          ? `${organisation.substring(0, MAX_ORGANISATION_LENGTH)}...`
          : organisation}</p>
                                                <p style={{width:'45%',fontFamily:"'Poppins', sans-serif", fontWeight:'500', color:'#6D767E', fontSize:'80%', textAlign:'center', overflow:'hidden', whiteSpace:'nowrap', textOverflow:"ellipsis" }}> {LinkedInUrl.length > MAX_LINKEDIN_URL_LENGTH
          ? `${LinkedInUrl.substring(0, MAX_LINKEDIN_URL_LENGTH)}...`
          : LinkedInUrl}</p>
                                                


                                                </div>
                                                <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between', padding:'2%', margin:'2%'}}>
                                                <FormControl >
                                                            <div style={{padding:'2%', margin:'2%'}}>
                                                                <FormLabel fontWeight="bold">
                                                                    Display name
                                                                </FormLabel>
                                                                <Input type="text" width='100%' style={{fontFamily:"'Poppins', sans-serif", fontWeight:'normal',}} onChange={(e)=>{setDisplayName(e.target.value)}} defaultValue={user.displayName}>


                                                                </Input>
                                                            </div>
                                                            <div style={{padding:'2%', margin:'2%'}}>
                                                                <FormLabel fontWeight="bold">
                                                                    Organisation
                                                                </FormLabel>
                                                                <Input type="text" width='100%' style={{fontFamily:"'Poppins', sans-serif", fontWeight:'normal'}} onChange={(e)=>{setOrganisation(e.target.value)}} defaultValue="Beriko">

                                                                </Input>
                                                            </div>
                                                            <div style={{padding:'2%', margin:'2%'}}>
                                                                <FormLabel fontWeight="bold">
                                                                    LinkedIn url
                                                                </FormLabel>
                                                                <Input type="text" width='100%'style={{fontFamily:"'Poppins', sans-serif", fontWeight:'normal'}} onChange={(e)=>{setLinkedInUrl(e.target.value)}} defaultValue="LinkedIn URL">

                                                                </Input>
                                                            </div>
                                                        
                                                        
                                                        
                                                        
                                                    </FormControl>
                                                </div>
                                            
                                            
                                        </div>
                                        <Button backgroundColor="#055566" alignSelf='center' color='white'>
                                            Save details
                                        </Button>
                                    </div>
                                    


                                </TabPanel>
                                <TabPanel>
                                    <div style={{background:'white', padding:'2%'}}>
                                        <div style={{display:'flex', flexDirection:'row' , justifyContent:'space-around',alignItems:'flex-start' ,borderRadius:'2%', padding:'10%'}}>
                                            
                                        <div className="form-container">
                                                    <FormControl >
                                                        
                                                            <FormLabel>
                                                                Change Email
                                                            
                                                            </FormLabel>
                                                            <Input type="text" width='100%' defaultValue={user.email} placeholder="enter an valid email">

                                                            </Input>
                                                            
                                                        
                                                        
                                                        
                                                    </FormControl>

                                                </div>
                                                <div className="form-container">
                                                    <FormControl isDisabled={user.googleId ? true : false} >
                                                        <FormLabel>
                                                            <FormLabel>
                                                                Current Password
                                                            </FormLabel>
                                                            <Input type="text" width='100%'>

                                                            </Input>
                                                            <FormLabel>
                                                                New password
                                                            </FormLabel>
                                                            <Input type="text" width='100%'>

                                                            </Input>
                                                            <FormLabel>
                                                                Retype new password
                                                            </FormLabel>
                                                            <Input type="text" width='100%'>

                                                            </Input>
                                                            <Stack
                                                                        direction={{ base: "column", sm: "row" }}
                                                                        align={"start"}
                                                                        justify={"space-between"}
                                                            >
              <Link color={"#055566"} onClick={()=>{router.push("/user/forgotpassword")}} >Forgot password?</Link>
            </Stack>
                                                        </FormLabel>
                                                    </FormControl>

                                                </div>
                                                
                                            
                                        </div>
                                        <Button backgroundColor="#055566" alignSelf='center' color='white'>
                                            Update details
                                        </Button>
                                        </div>
                                    
                                </TabPanel>
                            </TabPanels>
                        </Tabs>


                    </div>
                </div>
                ):(
                    router.push('/login')
                )
            } 
       </>
    )
}

export default Profile