//////////////++++++++++++++++++++CHAKRA UI++++++++++++++++/////
import { Image,Container,Text, Button } from "@chakra-ui/react";



//////++++++++++++++ Reusable react Components++++++++++///////////////
import { useEffect,useState } from "react";
import axios from "axios";
import HeroSection from "../../components/sections/HeroSection";
import Simple from "../../components/Navbar";
import Section3 from "../../components/sections/Section";
import ImageFirst from "../../components/sections/ImageFirst";
import Footer from "../../components/sections/Footer";
import ImageSecond from "../../components/sections/ImageSecond";
//////++++++++++++++ Style Sheets++++++++++///////////////
import '../stylesheets/homepage.css'
import './buyerlandingpage.css'


//++++++++++++++++++++++Assstes+++++++++++++++++++/////////
import UncoverSVG from '../../assets/uncover.svg'
import PuzzleSVG from '../../assets/puzzle.svg'
import HandshakeSVG from '../../assets/responsibility.svg'

const BuyerLandingPage = ()=>{
    const [user, setUser] = useState(null);
    const BuyerLandingPageHeroSectionStyle={
        background : "#AADCE7", 
    };
    useEffect(() => {
        const getUser = async () => {
          try {
            const config = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
              },
            };
            const borl = await axios.get("/api/auth/login/success", config);
            console.log(borl)
            setUser(borl.data.user);
            
          } catch (error) {
            console.log(error)
           
          }
        };
        getUser()
        }, []);
    return(
        <div className="landing-page-layout">
            {
            user?(
              <Simple userName={user.displayName} isLandingpage={false} email={user.email} isLoggedin={true}avatarUrl={user.image}/>
            ):(
              <Simple userName="{user.displayName}" isLandingpage={false} isLoggedin={false}  avatarUrl={"'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"}/>
            )

          }
            <HeroSection
                bgImg={BuyerLandingPageHeroSectionStyle}
                heroText = "Uncover Startup The That's Perfect For You"
                heroDesc = "Handpick a startup whose values, mission, and culture align with your own organization, with help of filters."
                isButton1needed = {true}
                button1text="Listings"
                rightElement={<Image src={UncoverSVG}/>}

            />
            <div>
                <ImageFirst
                    img = {PuzzleSVG}
                    buttonText = 'How do we do That?'
                    
                    tittle="Solve the Missing piece with ..."
                    desc = {<ul>
                        <li>Persnolised recommendations</li>
                        <li>Sort,filter, and select based on various characteristics</li>
                        <li> better decisions from handful amount of metrics</li>
                    </ul>  }

                    imgPadding={'10%'}
                />
           </div>
           <div>
                    <ImageSecond
                        featureImg = {HandshakeSVG}
                        head = "We Share Responsibility"
                        buttonText = "LISTINGS"
                        text="For providing seamless, end to end pipeline, secured pipeline for your acquisition process."
                    />
           </div>
           <div> 
                        <Container maxW={'container.xl'} py={12} padding={'5%'} >
                            <div className="content-container" >
                                    <div style={{width:'75%'}} >
                                        <Text color={'#055566'} >
                                            JOIN US TO EXPERIENCE THE SEAMLESS PIPELINE FOR ACQUISITION PROCESS.
                                            WE’D LOVE TO HELP YOU
                                        </Text>
                                    </div>
                                    <div >
                                        <Button  
                                        backgroundColor={"#055566"}
                                        color="white"
                                        _hover={{ bg: '#BEE5E7',color:'#055566',border:'1px solid #055566' }}
                                        > JOIN US</Button>

                                    </div>
                            </div>


                        </Container>

           </div>
           <div>
                        <Footer/>
           </div>
            
        </div>


    )
}

export default BuyerLandingPage;