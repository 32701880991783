import React, { useState, useEffect } from "react";
import "./acctype.css";
import axios from "axios";
import { Button } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import Buyer from "../assets/Buyer.svg";
import { UserState } from "../../Context";
import Seller from "../assets/seller.svg";
import Simple from "../../components/Navbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function Acctype() {
  const [isFocus, setIsFocus] = useState("None");
  const { user, setUser } = UserState();
  const toast = useToast();
  const history = useHistory();

  //TO GET THE USER DETAILS.

  useEffect(() => {
    const getUser = async () => {
      // console.log("heeeeeeeeeeeeeeeeeeeeeeeeejeeeeee")

      if (user.role && user.role !== "") {
        history.push("/");
      }
    };

    getUser();
  }, []);

  //TO HANDLE THE UPDATION OF THE ROLE
  const handleUpdateRole = async () => {
    const userId = user._id;
    if (isFocus == "none") {
      console.log("select an option");
    } else {
      try {
        const response = await axios.patch("/update/role", {
          userId,
          isFocus,
        });

        console.log("Updated user:", response.data);
        history.push("/");
      } catch (error) {
        console.error("Error updating role:", error);
      }
    }
  };

  const sellerclick = () => {
    setIsFocus("SELLER");
  };
  const buyerclick = () => {
    setIsFocus("BUYER");
  };
  const outclick = () => {
    setIsFocus("none");
  };

  const BoxStyle = {
    boxShadow: "dark-lg",
  };
  return (
    <div className="acctype">
      <Simple
        userName="{user.displayName}"
        isLandingpage={false}
        isLoggedin={false}
        flexStyleProps={BoxStyle}
        avatarUrl={
          "'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"
        }
      />
      <div className="acctype-content">
        <div className="acctype-box">
          {user ? (
            <h1>Hello {user.displayName}, your account created.</h1>
          ) : (
            <h1>Hello Guest, your account created.</h1>
          )}
          <div className="acctype-slection">
            <div
              className={`btn-box ${isFocus === "SELLER" ? "btn-focus" : ""}`}
              onClick={sellerclick}
            >
              <img src={Seller} />
              <p>SELLER</p>
            </div>
            <div
              className={`btn-box ${isFocus === "BUYER" ? "btn-focus" : ""}`}
              onClick={buyerclick}
            >
              <img src={Buyer} />

              <p>BUYER</p>
            </div>
            <Button
              onClick={handleUpdateRole}
              _hover={{ background: "#003f4c" }}
              _active={{ background: "#002835" }}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Acctype;
