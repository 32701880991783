import { Image, Text } from "@chakra-ui/react";
import EmptySvg from "../assets/wishlistEmpty.svg";
import "./stylesheet/excepcompostyles.css";

const HandleException = ({ text, size }) => {
  const getSizeStyles = () => {
    switch (size) {
      case 'small':
        return { boxSize: ['50%', '30%'], fontSize: ['lg', 'xl'] };
      case 'medium':
        return { boxSize: ['70%', '50%'], fontSize: ['xl', 'xx-large'] };
      case 'large':
        return { boxSize: ['80%', '70%'], fontSize: ['2xl', '3xl'] };
      default:
        return { boxSize: ['70%', '50%'], fontSize: ['xl', 'xx-large'] };
    }
  };

  const { boxSize, fontSize } = getSizeStyles();

  return (
    <div className="excep-section" style={{ textAlign: 'center' }}>
      <Image boxSize={boxSize} src={EmptySvg} alt="Empty" />
      <Text className="textsection" fontSize={fontSize}>
        {text}
      </Text>
    </div>
  );
};

export default HandleException;
