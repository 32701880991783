import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    Button,
    StackDivider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoSearchSharp,
  } from 'react-icons/io5';
  import { ReactElement } from 'react';
  import '../stylesheet/imagefirst.css'
  import '../stylesheet/herosection.css'
  import FeatureImg from '../assets/section4.png'
  
 
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function ImageFirst(props) {
    return (
      <section className='aim-section'>
      <Container maxW={'8xl'} py={12} padding={'2%'} >
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} >
        <Flex  flexDirection={{ sm: 'column', md: 'row' }}
      alignItems={{ base: 'center', md: 'flex-start' }}>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={props.img}
              objectFit={'cover'}
              padding={props.imgPadding}
              
            />
          </Flex>
          <div className="aim-grid-section">
          <Stack spacing={4} display={'flex'} justifyContent={'center'}>
            
            <Heading color={"#065666"} fontSize={{ base: '3xl', sm: '5xl', lg: '6xl' }}>{props.tittle}</Heading>
            <Text color={'gray.500'} fontSize={'lg'}>
             {props.desc}
            </Text>
            </Stack>
            <Button
           
           size={'lg'}
           fontWeight={'bold'}
           width={'fit-content'}
           px={6}
           bg={'#045567'}
           borderRadius='2px'
          
           color={'white'}
           onClick={props.handleClick}
           _hover={{ bg: 'none', color:'#045567',border:'2px solid #045567' }}>
           {props.buttonText}
         </Button>
         </div>
            
          
          
         
          
          
        </SimpleGrid>
      </Container>
      </section>
    );
  }
