

import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
} from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import logo from '../../assets/logo.png'
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { ReactNode } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Logo = (props: any) => {
  return (
    <>
            <Image src={logo}/>

    </>
  )
}



const SocialButton = ({
  children,
  label,
  href,
}
) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

export default function Footer() {
  
    <>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
<link href="https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap" rel="stylesheet"/>
    </>
    const isMobile = useMediaQuery({maxWidth:768})
    const componentStyle = {
        fontFamily: "'Lato', sans-serif",
        // Add other styles if needed
      };
      const router = useHistory()
  return (
    <Box
      bg={'#055467'}
      color={'white'}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        padding={'2%'}
        spacing={4}
        justify={'center'}
        align={'center'}>
        <Logo />
        <Stack direction={isMobile?'column':'row'} spacing={8} alignItems={'center'} fontFamily={componentStyle} fontWeight={'semi-bold'} fontSize={'12px'}>
          <Box as="a" onClick={()=>{router.push("/seller")}}>
            SELLERS
          </Box>
          <Box as="a"  onClick={()=>{router.push("/seller")}}>
            BUYERS
          </Box>
          <Box as="a" href={'#'}>
            ABOUT
          </Box>
          <Box as="a" href={'#'}>
            BLOG
          </Box>
          <Box as="a" href={'#'}>
            CONTACT US
          </Box>
          <Box as="a" href={'#'}>
            FAQ
          </Box>
        </Stack>
        <Text fontWeight={'bold'} fontSize={'10px'} letterSpacing={'1px'} color={'#b1b4b5'} > © 2021 . All Right Reserved</Text>

        <Text fontWeight={'bold'} fontSize={'10px'} letterSpacing={'1px'} color={'#b1b4b5'} >MADE FOR ENTREPRENEURS BY ENTREPRENEURS</Text>
      </Container>

      
    </Box>
  )
}