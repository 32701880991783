
import './stylesheet/cart.css'
import CartSVGComponent from '../assets/CartIconSVG';

const CartIcon = ({count})=>{
    return(
        <div className="cart">
        <span className="count">{count}</span>
        
        <i className="material-icons"><CartSVGComponent/></i>
        </div>
    )
}
export default CartIcon;