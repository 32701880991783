import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

function AnimatedSVG({ dataFromParent }) {
  const linevariant = {
    stroke: "#055566",
    "stroke-width": "3",
    strokeDasharray: "none",
  };
  const rectvariant = {
    fill: "#055566",
  };

  return (
    <div>
      <svg
        width="281"
        height="23"
        viewBox="0 0 281 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.line
          x1="12"
          y1="10.5"
          x2="98"
          y2="10.5"
          stroke="#77898F"
          strokeDasharray="2 2"
          animate={dataFromParent == 2 && linevariant}
          transition={{ duration: 1 }}
        />
        <motion.line
          x1="98"
          y1="11.5"
          x2="184"
          y2="11.5"
          stroke="#77898F"
          strokeDasharray="2 2"
          animate={dataFromParent == 3 && linevariant}
          transition={{ duration: 1 }}
        />
        <motion.line
          x1="184"
          y1="11.5"
          x2="270"
          y2="11.5"
          stroke="#77898F"
          strokeDasharray="2 2"
          animate={dataFromParent == 4 && linevariant}
          transition={{ duration: 1 }}
        />
        <rect width="23" height="23" rx="5" fill="#055566" />
        <motion.rect
          x="86"
          width="23"
          height="23"
          rx="5"
          fill="#77898F"
          animate={dataFromParent == 2 && rectvariant}
          transition={{ duration: 1 }}
        />
        <motion.rect
          x="172"
          width="23"
          height="23"
          rx="5"
          fill="#77898F"
          animate={dataFromParent == 3 && rectvariant}
          transition={{ duration: 1 }}
        />
        <motion.rect
          x="258"
          width="23"
          height="23"
          rx="5"
          fill="#77898F"
          animate={dataFromParent == 4 && rectvariant}
          transition={{ duration: 1 }}
        />
      </svg>
      {}
    </div>
  );
}

export default AnimatedSVG;
