import { ReactNode, useState } from "react";
import { useMediaQuery } from "react-responsive";
import logo from "../assets/logo.png";
import "./stylesheet/cart.css";
import CartIcon from "./CartIcon";

import { Icon } from "@chakra-ui/react";
import { extendTheme } from '@chakra-ui/react'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import useClickOutside from "../utils/OnClickOutside";
import "./stylesheet/Navbar.css";
import {
  Box,
  Flex,
  Image,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}
const theme = extendTheme({ breakpoints })
const Links = [ { text: "SELLERS", href: "/seller" },
{ text: "BUYERS", href: "/buyer" },
{ text: "RESOURCES", href: "/" },];

const NavLink = ({ children,href }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={href}
    fontSize="14x"
    fontWeight="650"
    letterSpacing="0.1em"
    color="#055566"
    fontFamily="Lato, sans-serif"
  >
    {children}
  </Link>
);

export default function Simple(props) {
  const isMobile = useMediaQuery({maxWidth:768})
  const isTablet = useMediaQuery({maxWidth:1024})
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBg, setScrollBg] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const menuRef = useClickOutside(onClose);
  // const history = useHistory()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrollBg(true);
    } else {
      setScrollBg(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // Close the menu when the screen width crosses 768px (adjust the value as needed)
    if (screenWidth >= 768) {
      console.log(screenWidth);
      onClose();
    }
  }, [screenWidth, onClose]);
  const history = useHistory();
  const toast = useToast();
  const handleSubmit = () => {
    history.push("/login");
  };
  const logout = async () => {
    try {
      window.open("http://localhost:5000/api/auth/logout", "_self");
    } catch (error) {
      console.log(error);
      toast({
        title: "Error Occured!",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };
  return (
    <>
      <Box >
       
        <Flex
          h={16}
          bg={scrollBg ? "rgba(217,238,242,0.9)" : "transparent"}
          margin={"0px"}
          zIndex={"12"}
          alignItems={"center"}
          justifyContent={isMobile?"start":"space-between"}
          position={"fixed"}
          width={"100%"}
          padding={"2%"}
          
          {...props.flexStyleProps}
        >
          <Icon
            size={"md"}
            as={HamburgerIcon}
            margin={'2%'}
            color={"#6D767E"}
            display={isTablet?'block':'none'}
            onClick={ onOpen}
          />
          <HStack justifyContent={'center'} alignItems={"center"}>
            <Box padding={isMobile?'2%':'0'} onClick={()=>{history.push('/')}} >
              <Image src={logo}  />
            </Box>
          </HStack>
          
          {props.isLandingpage && (
        <HStack as="nav" spacing={4} display={isMobile?'none':'flex'}>
          {Links.map((link, index) => (
            <NavLink key={index} href={link.href}>{link.text}</NavLink>
          ))}
        </HStack>
      )}
      
          <Flex  display={isTablet?'none':'flex'} alignItems={"center"} justifyContent={'space-between'} width={'10%'}>
            
           { props.user?(
              <Box onClick={()=>{history.push('/wishlist')}} >
                  <Icon as={CartIcon}  count={1} />
              </Box>
           ):(
            <Box onClick={()=>{history.push('/wishlist')}} >
                  <Icon as={CartIcon}/>
              </Box>
           )
            }
          
            <Menu>
              {props.isLoggedin ? (
                <>
                  <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                  >
                    <Avatar size={"sm"} src={props.avatarUrl} />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      fontWeight={"700"}
                      onClick={()=>{history.push("/profile")}}
                    >
                      <p className="profile-heading">{props.userName}</p>
                      <p>{props.email}</p>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      display={"flex"}
                      className="profile-content"
                      justifyContent={"center"}
                      
                    >
                      PROFILE
                    </MenuItem>
                    {/* <MenuItem
                      display={"flex"}
                      className="profile-content"
                      justifyContent={"center"}
                      onClick={()=>history.push("/wishlist")}
                    >
                      WISHLIST
                    </MenuItem> */}
                    <MenuItem
                      display={"flex"}
                      className="profile-content"
                      justifyContent={"center"}
                      onClick={logout}

                    >
                      LOGOUT
                    </MenuItem>
                  </MenuList>
                </>
              ) : (
                <MenuButton
                  as={Button}
                  rounded={"sm"}
                  backgroundColor={'transparent'}
                  border={'2px'}
                  _hover={{'backgroundColor':'#055566','color':'white'}}
                  cursor={"pointer"}
                  minW={0}
                  display={isMobile?'none':'flex'}
                  onClick={handleSubmit}

                 
                >
                  Login
                </MenuButton>
              )}
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4}
          
          ref={menuRef}>
            <Stack
              as={"nav"}
              spacing={4}
              width={'100%'}
              paddingTop={"12"}
              pb={4}
              zIndex={"12"}
              bg={scrollBg ? "rgba(217,238,242,0.9)" : "transparent"}
              position={"fixed"}
              display={"flex"}
              flexDirection={"column"}
            >
             {Links.map((link,index) => (
            <NavLink key={index} href={link.href}>{link.text}</NavLink>
          ))}
          {
            
            props.isLoggedIn ? (
              <>
              <NavLink href={'/'}>profile</NavLink>
              <NavLink href={'/'}>WISHLISTx</NavLink>
              <NavLink>Logout</NavLink>
              </>


            ):(
              <NavLink href={'/login'}>LOGIN</NavLink>

            )
          }
              
            </Stack>
          </Box>
        ) : null}
        
      </Box>
    </>
  );
}
