import Simple from "../components/Navbar";
import HeroSection from "../components/sections/HeroSection";
import Section2 from "../components/sections/Section2";
import LandingPageLayout from "../components/layouts/LandingPageLayout";
import Section3 from "../components/sections/Section";
import WhatWeDo from "../components/sections/WhatWeDoSection";
import "./stylesheets/homepage.css";
import HandshakeSection from "../components/sections/BridgingTheGap";
import ImageFirst from "../components/sections/ImageFirst";
import FeatureImage from "../assets/section4.png";
import Section6Image from "../assets/section6.png";
import ImageSecond from "../components/sections/ImageSecond";
import { useRef, useEffect } from "react";
import PhoneImg from "../assets/phone-section.png";
import RoadmapSection from "../components/sections/RoadmapSection";
import Testimonials from "../components/sections/Testimonials";
import Footer from "../components/sections/Footer";
import Bgimg from "../assets/BG.png";

import axios from "axios";
import { Text, Image, VStack, HStack, Box, Button } from "@chakra-ui/react";
import { useState } from "react";

import ReactCardCarousel from "react-card-carousel";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SaasImage from "../../src/assets/saas.png";
import "./stylesheets/listingdescription.css";
import Calendar from "../assets/Icons/date.svg";
import TeamSize from "../assets/Icons/team.svg";
import Dollar from "../assets/Icons/dollar.svg";
import Customers from "../assets/Icons/customers.svg";
import { UserState } from "../Context";
const cardStyles = {
  height: "400px",
  width: "300px",
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  background: "rgba(15, 183, 216,0.9)",
  color: "#FFF",
  padding: "5%",

  fontSize: "12px",
  textTransform: "uppercase",
  borderRadius: "10px",
  boxSizing: "border-box",
};

const TestHomepage = () => {
  const router = useHistory();
  const handleListingsClick = () => {
    router.push("/listing");
  };
  const handleJoinUsClick = () => {
    router.push("/login");
  };
  const cardCarousel = (
    <ReactCardCarousel autoplay={true} autoplay_speed={1500}>
      <div style={cardStyles}>
        <div
          style={{
            margin: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          <Text className="price-intrested" fontSize="lg">
            PhotographyListing
          </Text>

          <Text>SAAS</Text>
          <div className="price-intrested">
            <Text className="price-intrested">$250M</Text>
          </div>
          <div className="company-metrics">
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Calendar} />
                  </Box>
                  <Text className="metrics-desc"> March 2023</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TEAM SIZE</Text>
                <HStack>
                  <Box>
                    <Image src={TeamSize} />
                  </Box>
                  <Text className="metrics-desc"> 4</Text>
                </HStack>
              </VStack>
            </div>
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TTM Revenue</Text>
                <HStack>
                  <Box>
                    <Image src={Dollar} />
                  </Box>
                  <Text className="metrics-desc">$255,000</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Customers} />
                  </Box>
                  <Text className="metrics-desc"> 10,000</Text>
                </HStack>
              </VStack>
            </div>
            <Button
              background={"#055566"}
              _hover={{
                background: "inherit",
                border: "2px solid #055566",
                color: "#055566",
              }}
              style={{ fontFamily: "'Poppins', sans-serif" }}
              color={"white"}
            >
              INTRESTED
            </Button>
          </div>
        </div>
      </div>
      <div style={cardStyles}>
        <div
          style={{
            margin: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "poppins,'sans-seriff'",
          }}
        >
          <Text fontSize="xl" style={{ fontWeight: "bold", color: "#055566" }}>
            PhotographyListing
          </Text>

          <Text>SAAS</Text>
          <div className="price-intrested">
            <Text className="price-intrested">$300M</Text>
          </div>
          <div className="company-metrics">
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Calendar} />
                  </Box>
                  <Text className="metrics-desc"> March 2023</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TEAM SIZE</Text>
                <HStack>
                  <Box>
                    <Image src={TeamSize} />
                  </Box>
                  <Text className="metrics-desc"> 4</Text>
                </HStack>
              </VStack>
            </div>
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TTM Revenue</Text>
                <HStack>
                  <Box>
                    <Image src={Dollar} />
                  </Box>
                  <Text className="metrics-desc">$255,000</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Customers} />
                  </Box>
                  <Text className="metrics-desc"> 10,000</Text>
                </HStack>
              </VStack>
            </div>
            <Button
              background={"#055566"}
              _hover={{
                background: "inherit",
                border: "2px solid #055566",
                color: "#055566",
              }}
              style={{ fontFamily: "'Poppins', sans-serif" }}
              color={"white"}
            >
              INTRESTED
            </Button>
          </div>
        </div>
      </div>
      <div style={cardStyles}>
        <div
          style={{
            margin: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "poppins,'sans-seriff'",
          }}
        >
          <Text fontSize="xl" style={{ fontWeight: "bold", color: "#055566" }}>
            PhotographyListing
          </Text>

          <Text>SAAS</Text>
          <div className="price-intrested">
            <Text className="price-intrested">$30M</Text>
          </div>
          <div className="company-metrics">
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Calendar} />
                  </Box>
                  <Text className="metrics-desc"> March 2023</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TEAM SIZE</Text>
                <HStack>
                  <Box>
                    <Image src={TeamSize} />
                  </Box>
                  <Text className="metrics-desc"> 4</Text>
                </HStack>
              </VStack>
            </div>
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TTM Revenue</Text>
                <HStack>
                  <Box>
                    <Image src={Dollar} />
                  </Box>
                  <Text className="metrics-desc">$255,000</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Customers} />
                  </Box>
                  <Text className="metrics-desc"> 10,000</Text>
                </HStack>
              </VStack>
            </div>
            <Button
              background={"#055566"}
              _hover={{
                background: "inherit",
                border: "2px solid #055566",
                color: "#055566",
              }}
              style={{ fontFamily: "'Poppins', sans-serif" }}
              color={"white"}
            >
              INTRESTED
            </Button>
          </div>
        </div>
      </div>
      <div style={cardStyles}>
        <div
          style={{
            margin: "2%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "poppins,'sans-seriff'",
          }}
        >
          <Text fontSize="xl" style={{ fontWeight: "bold", color: "#055566" }}>
            PhotographyListing
          </Text>

          <Text>SAAS</Text>
          <div className="price-intrested">
            <Text className="price-intrested">$2B</Text>
          </div>
          <div className="company-metrics">
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Calendar} />
                  </Box>
                  <Text className="metrics-desc"> March 2023</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TEAM SIZE</Text>
                <HStack>
                  <Box>
                    <Image src={TeamSize} />
                  </Box>
                  <Text className="metrics-desc"> 4</Text>
                </HStack>
              </VStack>
            </div>
            <div className="dt-tm">
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> TTM Revenue</Text>
                <HStack>
                  <Box>
                    <Image src={Dollar} />
                  </Box>
                  <Text className="metrics-desc">$255,000</Text>
                </HStack>
              </VStack>
              <VStack padding={"2%"} margin={"2%"}>
                <Text className="metrics-header"> DATE FOUNDED</Text>
                <HStack>
                  <Box>
                    <Image src={Customers} />
                  </Box>
                  <Text className="metrics-desc"> 10,000</Text>
                </HStack>
              </VStack>
            </div>
            <Button
              background={"#055566"}
              _hover={{
                background: "inherit",
                border: "2px solid #055566",
                color: "#055566",
              }}
              style={{ fontFamily: "'Poppins', sans-serif" }}
              color={"white"}
            >
              INTRESTED
            </Button>
          </div>
        </div>
      </div>
    </ReactCardCarousel>
  );

  const sectionRef = useRef(null);
  const LandingPageHerosectionStyle = {
    backgroundImage: `url(${Bgimg})`,
    // Add any other styles you want for the section here
  };
  // const [user, setUser] = useState(null);
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        sectionRef.current.classList.add("fadeInAnimation");
      } else {
        sectionRef.current.classList.remove("fadeInAnimation");
      }
    });
  };
  const { user, setUser } = UserState();
  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       const config = {
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Credentials": true,
  //         },
  //         withCredentials: true
  //       };
  //       const borl = await axios.get("/api/auth/login/success", config);
  //       console.log("borl",borl)
  //       setUser(borl.data.user);

  //     } catch (error) {
  //       console.log(error)

  //     }
  //   };
  //   getUser()
  //   }, []);

  return (
    <div ref={sectionRef} className="landing-page-layout">
      {user ? (
        <Simple
          userName={user.displayName}
          isLandingpage={true}
          email={user.email}
          isLoggedin={true}
          avatarUrl={user.image}
          user={user}
        />
      ) : (
        <Simple
          userName="{user.displayName}"
          isLandingpage={true}
          isLoggedin={false}
          avatarUrl={
            "'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"
          }
        />
      )}

      <HeroSection
        bgImg={LandingPageHerosectionStyle}
        heroText=" Get started on your M&A Journey with us"
        heroDesc=" Merger & Acquisition is a tricky business. We make it simple and easy.
          Simplified M&A for the new age business 
          "
        isButton1needed={true}
        button1text="Listings"
        handleButton1Click={handleListingsClick}
        isButton2needed={true}
        handleButton2Click={handleJoinUsClick}
        button2text="Join us"
        rightElement={cardCarousel}
      />

      <Section3 />
      <ImageFirst
        img={FeatureImage}
        buttonText="know more"
        text="What we Do"
        tittle="What we Do?"
        imgPadding={"10%"}
        desc="
          Acquire Easy streamlines M&A for businesses. Our experts guide you through, ensuring successful transactions and maximum growth with minimal risk. We're here to make it easy for you."
      />

      <HandshakeSection />
      <ImageSecond
        head="Aim High Sell Bold!"
        featureImg={Section6Image}
        buttonText="KNOW MORE"
        text="Choosing to start a business is not easy. But choosing to let go of that business is even harder. We stand by entrepreneurs who are in the next phase of their journey and help them find the right business for them to sell to and exit.
"
        handleClick={() => {
          router.push("/seller");
        }}
      />
      <ImageFirst
        img={PhoneImg}
        buttonText="know more"
        tittle="Acquire businesses, quick and easy !"
        desc="Identifying the right business that will be a value add to your portfolio is a time consuming and risky process. You need the right people by your side through the journey, a luxury most business dont have. Let us help you"
        handleClick={() => {
          router.push("/buyer");
        }}
        imgPadding={"10%"}
      />
      <RoadmapSection />
      <Testimonials />
      <Footer />
    </div>
  );
};
export default TestHomepage;
