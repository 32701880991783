import React from "react";
import "./seller.css";
import { BsFillChatRightQuoteFill } from "react-icons/bs";
import { Button } from "@chakra-ui/react";
import Footer from "../../components/sections/Footer";
function Seller() {
  return (
    <div className="head">
      <div className="seller-herobar">
        <div className="herobar-heading">
          <h1>Unlock Your Startup's Potential: Sell And Prosper</h1>
        </div>
      </div>
      <div className="seller-herosupport">
        <p>
          Transform Your Startup Journey With Our Exclusive Seller Platform.
          Discover The PErfect Oppurtunity To Sell YOur Business And Reap The
          Rewards Of Your Hard Work. Join A Community Of Visionary
          Enterpreneurs, Investors, And Passionate Buyers, And Make Your Mark On
          The World Of Startup Acquistions Today!
        </p>
      </div>
      {/* <div className="seller-summary"></div> */}
      <div className="summary-content">
        <h1>Smooth Deals: Guaranteed!</h1>
        <p>
          No More Nail-Biting Negotiations! We Make Sure Your Deals Close
          Smoothly And You Can Sail Through The Entire Process Like A Breeze.
        </p>
        <p>
          Our Platform Ensures Safe Transactions And Reliable Interactions.
          Trust Is Our Middle Name, Or At Least, It Would Be If We Had One.
        </p>
        <p>
          Time Is Money, And We Save You Both! Our Efficient System Delivers
          Quick Results, Letting You Focus On More Important Tasks At Hand.
        </p>
      </div>
      <div className="seller-testimonial">
        <div className="seller-testimonial-box">
          <div className="seller-testimonial-review">
            <p>
              {" "}
              <BsFillChatRightQuoteFill
                size={60}
                style={{ color: "#FFF", opacity: 0.3 }}
              />
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras quis
              interdum velit. Fusce volutpat posuere volutpat. Aenean urna dui,
              tincidunt dapibus neque ac, aliquet placerat dolor.
            </p>
            <p>Anthony Bahringer</p>
            <p>Senior Research Manager</p>
          </div>
          <img src={require("../assets/review-img.png")} />
        </div>
      </div>
      <div className="seller-points">
        <div className="seller-special-content">
          <h1>What Makes Us Special?</h1>
          <h2>Top Notch Support</h2>
          <p>Our dedicated Team Is Always Ready To Guide And Assist You.</p>
          <h2>Genuine Partners</h2>
          <p>
            Robust verification and screening process to ensure the
            authenticity.
          </p>
          <h2>Trustworthy Platform</h2>
          <p>You can rely on us for secure and transparent transactions.</p>
        </div>
        <img src={require("../assets/special-img.png")} />
      </div>
      <div className="seller-explore">
        <h1>Ready To Explore?</h1>
        <p>
          Experience hassle-free selling with our innovative platform! Don’t
          wait, start selling faster now.
        </p>
        <Button
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          SIGN UP
        </Button>
      </div>
      <div className="seller-footer">
        <Footer />
      </div>
    </div>
  );
}

export default Seller;
