import { useHistory,  } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Simple from "../components/Navbar";
import HandleException from "../components/HandleExceptions";
import { useMediaQuery } from "react-responsive";
import { useState,useEffect } from "react";

import './stylesheets/listingdescription.css'
import Saas from '../../src/assets/saas.png'
import { CChart } from '@coreui/react-chartjs';
// Import 'getStyle' function from CoreUI React
import { getStyle } from '@coreui/utils';
import { Card, Stack,HStack,VStack,CardHeader, CardBody, CardFooter ,Text, Container,Button,ButtonGroup,Box,Image,Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Spinner} from '@chakra-ui/react'
import { BsHeart } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import NotFoundPage from "../components/404"
import Loader from "../components/Spinner";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
  } from '@chakra-ui/react'
  import Calendar from '../assets/Icons/date.svg'
  import TeamSize from '../assets/Icons/team.svg'
  import Dollar from '../assets/Icons/dollar.svg'
  import Customers from '../assets/Icons/customers.svg'
  import Blocker from '../assets/blocker.svg'
import { ChevronRightIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useToast } from "@chakra-ui/toast";

import Footer from "../components/sections/Footer";

const ListingDescription =()=>
{
    const id = useParams()
    const toast = useToast();
    const router= useHistory()
    
    const isMobile = useMediaQuery({maxWidth:770})
    const [Loading, setIsLoading] = useState(true)
    const [isAtwClicked, setIsAtwClicked] = useState(false)
    const [Listingdata,setListingData] =  useState(null)
    const [isNotFound, setIsNotFound] = useState(false)
    //atw:- add to wishlist

   
    const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        withCredentials: true,
      };
    const api_url = `http://localhost:8085/listing/${id}`
    const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabStyle = (index) => ({
    color: activeTab === index ? '#055566' : 'black',
    borderRight: activeTab === index ? '2px solid #055566' : 'none',
    fontWeight: activeTab === index ? '800  ' : 'none',

    paddingRight: '10px', // Add some right padding to separate tabs visually
  });
    const fetchListingData=async ()=>{
        
        try{
            
            const borl = await axios.get(`/listing/${id.id}`, config);
           
            if(borl.status == 401){
                toast({
                    title:'Please login again',
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom",
                })
                router.goBack()
            

            }
            else if(borl.status == 404){
                setIsNotFound(true)
                
            }
            else{
               
                
                setListingData(borl.data.listing)
                setIsAtwClicked(borl.data.isAddedToWishlist)
            }
        


        }catch(error){
            console.log(error)
            if (error.response && error.response.status === 401) {
                toast({
                    title: 'Please login again',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
                router.push('/login');
            }
            else if(error.response && error.response.status === 404){
                setIsNotFound(true)
            } 
            else {
                setIsNotFound(true)
                console.error(error);
            }
        }
        finally{
            setIsLoading(false)
        }

    }
    useEffect(()=>{
        fetchListingData();

    })
    
    if(Loading){
        return <Loader/>
    }
    
    else if(isNotFound){
      return <NotFoundPage/>
    }

    const handleAddToWishLisht = async ()=>{
        const data = {listingId:Listingdata._id}
        try{
       const response = await axios.patch('/user/wishlist/add',data,config)
       setIsAtwClicked(true)
        }catch(error){
            if (error.response && error.response.status === 401) {
                toast({
                    title: 'Please login again',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
                router.push('/login');
            }
            else if(error.response && error.response.status === 500){
                toast({
                    title: 'Error occured during update',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
            } 
            else {
                toast({
                    title: 'Oops server didnt recognize you',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
                console.error(error);
            }

        }

    }
    const handleRemovefromWishlist = async ()=>{
        const data = {id:Listingdata._id}
        try{
       const response = await axios.patch('/user/wishlist/remove',data,config)
       setIsAtwClicked(false)
        }catch(error){
            if (error.response && error.response.status === 401) {
                toast({
                    title: 'Please login again',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
                router.push('/login');
            }
            else if(error.response && error.response.status === 500){
                toast({
                    title: 'Error occured during update',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
            } 
            else {
                toast({
                    title: 'Oops server didnt recognize you',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
                console.error(error);
            }

        }


    }
    const toggleWishList = () =>{
        if(!isAtwClicked)
        {
            console.log("Added to wishlist")
            handleAddToWishLisht()
        }else{
            console.log("Removed from wishList");
            handleRemovefromWishlist()
        }
    }
    
    return(

        <>
            <>
            <Simple userName="{user.displayName}" isLoggedin={false}avatarUrl={"'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"}/>
            <Container maxW={'7xl'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        
            <Breadcrumb spacing='8px' padding={isMobile?'20%':'5%'} separator={<ChevronRightIcon color='gray.500' />}>
                <BreadcrumbItem>
                    <BreadcrumbLink onClick={()=>{router.push('/')}} fontWeight={'500'}>Home</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                    <BreadcrumbLink onClick={()=>{router.push('/listing')}} fontWeight={'500'}>Listings</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href='#' fontWeight={'700'} color={'#045567'}>{Listingdata.uniqueName}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Container maxW={'7xl'} >
            <div className="listings-card-container">
            <Card>
                <CardBody backgroundColor={"#EAFBFF"}>
                    <div className="card-inside-container">
                        <div className="company-category-container">
                            <Box ><Image src={Saas}></Image></Box>
                            <Text className="company-location" >Canada, UK</Text>
                            <Button leftIcon={isAtwClicked?<FaHeart/>:<BsHeart/>} border="2px solid transparent" style={{transition: '0.3s',}} onClick={()=>{toggleWishList()}} _hover={{background:'inherit',border:'2px solid #055566'}} color={'#055566;'} fontWeight={'bold'} className="add-to-wishlist" variant='ghost'>
                               { isAtwClicked?("Remove from wishlist") :("Add to wishlist")}
                            </Button>
                        </div>
                        <div className="company-metrics">
                        <div className="dt-tm">
                            <VStack padding={'2%'} margin={'2%'}>
                                <Text className="metrics-header"> DATE FOUNDED</Text>
                                <HStack >
                                    <Box><Image src={Calendar}/></Box>
                                    <Text className="metrics-desc"> March 2023</Text>
                                </HStack>
                            </VStack>
                            <VStack padding={'2%'} margin={'2%'}>
                                <Text className="metrics-header"> TEAM SIZE</Text>
                                <HStack>
                                    <Box><Image src={TeamSize}/></Box>
                                    <Text className="metrics-desc"> 4</Text>
                                </HStack>
                            </VStack>
                        </div>
                        <div className="dt-tm">
                            <VStack padding={'2%'} margin={'2%'}>
                                <Text className="metrics-header"> TTM Revenue</Text>
                                <HStack>
                                    <Box><Image src={Dollar}/></Box>
                                    <Text className="metrics-desc">$255,000</Text>
                                </HStack>
                            </VStack>
                            <VStack padding={'2%'} margin={'2%'}>
                                <Text className="metrics-header"> DATE FOUNDED</Text>
                                <HStack>
                                    <Box><Image src={Customers}/></Box>
                                    <Text className="metrics-desc"> 10,000</Text>
                                </HStack>
                            </VStack>
                        </div>

                        </div>
                        <div className="blocker">
                            <Box><Image src={Blocker}/></Box>

                        </div>
                        {!isMobile &&
                        <div className="price-intrested">
                            <Text className="price-intrested">$250M</Text>
                            <Button background={'#055566'} style={{ fontFamily: "'Poppins', sans-serif" }}  _hover={{background:'inherit', border:'2px solid #055566', color:'#055566'}} color={'white'}>INTRESTED</Button>

                        </div>
    }

                    </div>
                </CardBody>
            </Card>
            </div>
            <div className="listings-card-container">
            <Card backgroundColor={"#EAFBFF"}>
                <CardBody>
                <Tabs position="relative" variant={isMobile?'enclosed':'unstyled'} >
                    <TabList style={isMobile?{ overflow:  'scroll', scrollbarWidth: 'none','-ms-overflow-style': 'none'}:{overflow:  'hidden'}} >
                        <Tab style={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={'800'}>DESCRIPTION</Tab>
                        <Tab style={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={'800'}>TECH STACK</Tab>
                        <Tab style={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={'800'}>REASON FOR ASKING PRICE</Tab>
                    </TabList>
                    {!isMobile && (
                        <TabIndicator
                            mt="-1.5px"
                            height="5px"
                            bg="#055566"
                            borderRadius="5px"
                            color={'#055566'}
                        />
                    )}
                    <TabPanels>
                    <TabPanel>
                        <p> {Listingdata.detailedDescription || Listingdata.description}</p>
                    </TabPanel>
                    <TabPanel>
                       {
                        Listingdata.isTechStackAvailable?(
                            <ul>
                                {Listingdata.techStack.map((tech, index) => (
                                    <li key={index}>{tech}</li>
                                ))}
                             </ul>

                        ):(
                            <HandleException text="Tech stack information is not available." size="small"/>
                        )
                        }
                    </TabPanel>
                    <TabPanel>
                       {
                            Listingdata.ReasonForAskingPrice?(
                                <p>{Listingdata.ReasonForAskingPrice}</p>

                            ):(
                                <HandleException text="Its emtpy here" size="small"/> 

                            )
                       }
                    </TabPanel>
                    </TabPanels>
                </Tabs>
                </CardBody>
            </Card>
            </div>

            <div className="listings-card-container">
                <Card backgroundColor={"#EAFBFF"}>
                    <CardBody>
                        <Tabs position="relative" variant="unstyled" >
                            <TabList>
                            <Tab style={{ fontFamily: "'Poppins', sans-serif" }} fontWeight={'800'}>METRICS</Tab>

                            </TabList>
                            < TabIndicator
                                mt="-1.5px"
                                height="5px"
                                bg="#055566"
                                borderRadius="5px"
                                color={'#055566'}
                            />
                            <TabPanels>
                                <TabPanel>
                                <Tabs position="relative" variant={isMobile?'enclosed':'unstyled'} orientation={isMobile?'horizontal':"vertical"}>
                                {!isMobile &&
                                    <TabList>
                                        <Tab onClick={() => handleTabClick(0)} style={tabStyle(0)}>One</Tab>
                                        <Tab onClick={() => handleTabClick(1)} style={tabStyle(1)}>Two</Tab>
                                        <Tab onClick={() => handleTabClick(2)} style={tabStyle(2)}>Three</Tab>
                                    </TabList>
                                }
                                {isMobile &&
                                    <TabList>
                                        <Tab >One</Tab>
                                        <Tab >Two</Tab>
                                        <Tab >Three</Tab>
                                    </TabList>
                                }
                                
                                    <TabPanels>
                                        <TabPanel>
                                            <div className="chart-container">
                                            <CChart
                                                type="line" 
                                                data={{
                                                    labels: ["January", "February", "March", "April", "May", "June", "July"],
                                                    datasets: [
                                                    {
                                                        label: "My First dataset",
                                                        backgroundColor: "rgba(220, 220, 220, 0.2)",
                                                        borderColor: "#0FB5D8",
                                                        pointBackgroundColor: "#0FB5D8",
                                                        pointBorderColor: "#fff",
                                                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
                                                    },
                                                    {
                                                        label: "My Second dataset",
                                                        backgroundColor: "#D9EEF2",
                                                        borderColor: "rgba(151, 187, 205, 1)",
                                                        pointBackgroundColor: "rgba(151, 187, 205, 1)",
                                                        pointBorderColor: "#fff",
                                                        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
                                                    },
                                                    ],
                                                }}
                                                options={{
                                                    plugins: {
                                                    legend: {
                                                        labels: {
                                                        color: getStyle('--cui-body-color'),
                                                        }
                                                    }
                                                    },
                                                    scales: {
                                                    x: {
                                                        grid: {
                                                        color: getStyle('--cui-border-color-translucent'),
                                                        },
                                                        ticks: {
                                                        color: getStyle('--cui-body-color'),
                                                        },
                                                    },
                                                    y: {
                                                        grid: {
                                                        color: getStyle('--cui-border-color-translucent'),
                                                        },
                                                        ticks: {
                                                        color: getStyle('--cui-body-color'),
                                                        },
                                                    },
                                                    },
                                                }}
                                            />
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <CChart
                                            type="line" 
                                            data={{
                                                labels: ["January", "February", "March", "April", "May", "June", "July"],
                                                datasets: [
                                                {
                                                    label: "My First dataset",
                                                    backgroundColor: "rgba(220, 220, 220, 0.2)",
                                                    borderColor: "#0FB5D8",
                                                    pointBackgroundColor: "#0FB5D8",
                                                    pointBorderColor: "#fff",
                                                    data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
                                                },
                                                {
                                                    label: "My Second dataset",
                                                    backgroundColor: "#D9EEF2",
                                                    borderColor: "rgba(151, 187, 205, 1)",
                                                    pointBackgroundColor: "rgba(151, 187, 205, 1)",
                                                    pointBorderColor: "#fff",
                                                    data: [1150, 1222, 2228, 2922, 227, 2225, 1222, 7220, 6022]
                                                },
                                                ],
                                            }}
                                            options={{
                                                plugins: {
                                                legend: {
                                                    labels: {
                                                    color: getStyle('--cui-body-color'),
                                                    }
                                                }
                                                },
                                                scales: {
                                                x: {
                                                    grid: {
                                                    color: getStyle('--cui-border-color-translucent'),
                                                    },
                                                    ticks: {
                                                    color: getStyle('--cui-body-color'),
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                    color: getStyle('--cui-border-color-translucent'),
                                                    },
                                                    ticks: {
                                                    color: getStyle('--cui-body-color'),
                                                    },
                                                },
                                                },
                                            }}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                        <CChart
                                            type="line" 
                                            
                                            options={{
                                                responsive:true,
                                                plugins: {
                                                legend: {
                                                    labels: {
                                                    color: getStyle('--cui-body-color'),
                                                    }
                                                }
                                                },
                                                scales: {
                                                x: {
                                                    grid: {
                                                    color: getStyle('--cui-border-color-translucent'),
                                                    },
                                                    ticks: {
                                                    color: getStyle('--cui-body-color'),
                                                    },
                                                },
                                                y: {
                                                    grid: {
                                                    color: getStyle('--cui-border-color-translucent'),
                                                    },
                                                    ticks: {
                                                    color: getStyle('--cui-body-color'),
                                                    },
                                                },
                                                },
                                            }}
                                            />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </CardBody>
            </Card>
            </div>
            

            </Container>
            

            </Container>
            {isMobile &&
            <footer className="button-footer">
                <div className="button-footer-section">
                <Text className="price-intrested" fontSize={'100%'}>$250M</Text>
                <Button background={'#055566'} style={{ fontFamily: "'Poppins', sans-serif" }} color={'white'}>INTRESTED</Button>
                </div>
            </footer>
    }
            <Footer></Footer>
            </>
        </>
        
       
    )
    



}
export default ListingDescription;