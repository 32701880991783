import { Route } from "react-router-dom/cjs/react-router-dom.min";
import "./App.css";
import TestHomepage from "./Pages/TestHomepage";
import Authtest from "./Pages/authtest/Authtest";
import Seller from "./Pages/Seller/Seller";
import Listingpage from "./Pages/listingpage/Listingpage";
import SellerDashboard from "./Pages/Dashboard";
import TestListpage from "./Pages/Testlistingspage";
import ListingDescription from "./Pages/ListingDescription";
import Acctype from "./Pages/acctype/Acctype";
import Sellerform from "./Pages/sellerform/Sellerform";
import Wishlist from "./Pages/buyer/WishList";
import BuyerLandingPage from "./Pages/buyer/BuyerLandingPage";
import forgotPassword from "./Pages/ForgotPassword";
import ProfilePage from "./Pages/Profile";
import Buyerform from "./Pages/buyerform/Buyerform";
import ResetPassword from "./Pages/Resetlink";
import acqprofile from "./Pages/sellerprofile/Sellerprofile";
function App() {
  return (
    <div className="App">
      <Route path="/" component={TestHomepage} exact />
      <Route path="/login" component={Authtest} />
      <Route path="/seller" component={Seller} />
      <Route path="/listing" exact component={Listingpage} />
      <Route path="/listing/:id" component={ListingDescription}/>
      <Route path="/listingdescription" component={ListingDescription} />
      <Route path="/acctype" component={Acctype} />
      <Route path="/sellerform" component={Sellerform} />
      <Route path="/buyer" component={BuyerLandingPage} />
      <Route path="/edit" component={SellerDashboard} />
      <Route path="/user/forgotpassword" component={forgotPassword} />
      <Route path="/wishlist" component={Wishlist} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/buyerform" component={Buyerform} />
      <Route
        path="/support/password-reset/:id/:token"
        component={ResetPassword}
      />
      <Route path="/acqprofile" component={acqprofile} />
    </div>
  );
}

export default App;
