import React from 'react';
import { Spinner } from '@chakra-ui/react';

const Loader = () => {
  const centerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    padding: '20px',
    maxWidth: '600px', // Set a maximum width for larger screens
    width: '90%', // Set a width for smaller screens

  };

  return (
    <div style={centerStyle}>
      <Spinner size='xl'/>
    </div>
  );
};

export default Loader
