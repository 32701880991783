import React, { useEffect } from "react";
import "./sellerform.css";
import { Button } from "@chakra-ui/react";
import levelindicator from "../assets/Level Indicator.svg";
import Sellerformone from "../../components/sellerforms/Sellerformone";
import Sellerformtwo from "../../components/sellerforms/Sellerformtwo";
import Sellerformthree from "../../components/sellerforms/Sellerformthree";
import Sellerformfour from "../../components/sellerforms/Sellerformfour";
import { useState } from "react";
import AnimatedSVG from "../../components/AnimatedSVG";

function Sellerform() {
  const [position, setposition] = useState(1);
  const [formData, setFormData] = useState({
    sellerProfile: {
      compname: "",
      location: "",
      teamsize: "",
      category: "",
      description: "",
    },
    financials: {
      twelverevenue: "",
      lastmonthrevenue: "",
      overallrevenue: "",
      overallnetprofit: "",
      twelvenetprofit: "",
      acquistiontype: "",
    },
    compinfo: {
      customercount: "",
      usercount: "",
      competitorone: "",
      competitortwo: "",
    },
    techstack: {
      language: "",
      framework: "",
      database: "",
      serverproviders: "",
    },
  });

  const handleChildData = (data) => {
    setposition(data);
  };
  const handleFormChange = (step, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data,
    }));
  };

  return (
    <div className="sellerform">
      <div>Navbar</div>
      <div className="sellerform-content">
        <div className="sellerform-box">
          <AnimatedSVG dataFromParent={position} />
          <div>
            {position == 1 && (
              <Sellerformone
                onDataUpdate={handleChildData}
                data={formData.sellerProfile}
                step="sellerProfile"
                onFormChange={handleFormChange}
              />
            )}
            {position == 2 && (
              <Sellerformtwo
                onDataUpdate={handleChildData}
                data={formData.financials}
                step="financials"
                onFormChange={handleFormChange}
              />
            )}
            {position == 3 && (
              <Sellerformthree
                onDataUpdate={handleChildData}
                data={formData.compinfo}
                step="compinfo"
                onFormChange={handleFormChange}
              />
            )}
            {position == 4 && (
              <Sellerformfour
                onDataUpdate={handleChildData}
                data={formData.techstack}
                step="techstack"
                onFormChange={handleFormChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sellerform;
