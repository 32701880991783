import React from "react";
import "./stylesheet/404.css"
import { Text } from "@chakra-ui/react";

const NotFoundPage = ()=>{
    return(
        <div className="empty-section">
        
        <Text className="textsection" fontSize={'xxx-large'}>404</Text>
        <Text className="textsection" fontSize={'xx-large'}>What..? not there in our Database?</Text>
        
         </div>
    )
}
export default NotFoundPage