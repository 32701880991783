//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~React~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import { useState } from "react";
//`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Chakra UI~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import Simple from "../components/Navbar";
import { FormControl,Input, Stack , Button } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`Reusable react components~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import HeroSection from "../components/sections/HeroSection";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~CSS~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import './stylesheet/forgotpassword.css'
const ForgotPasswordCard = ()=>{
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [email, setForgotEmail] = useState('')
    const [isResponseOk, setIsResponseOk] = useState(true)
    const handleForgotPasswordSubmit = async (event)=>{
        event.preventDefault()
        setIsSubmitted(true)
        try{
            const response = await fetch('http://localhost:5000/api/user/forgotpassword',{
                method:'POST',
                credentials:'include',
                headers:{
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email})
            })
            const data =  await response.json()
            console.log(data)
            if(!response.ok){
                console.log('response ',response)
                setIsResponseOk(false)
            }
        }catch(e){
            console.log(e)
        }
    }
    
    

    
    return(
        <div className="forget-passwrod-card">
            {
                isResponseOk?(
                    null

                ):(
                    <p style={{color:'red'}}>This user email not found</p>

                )
            }
            
             <FormControl id="email">
            <Input
                focusBorderColor="#055566"
                variant="flushed"
                placeholder="Last remembered email"
                type="email"
                borderBottomColor="#055566"
                
                onChange={(e) => setForgotEmail(e.target.value)}
                _placeholder={{ opacity: 1, color: "#055566" }}
            />
        </FormControl>
        <div style={{padding:'2%', margin:'2%'}}>
        {isSubmitted?( 
            isResponseOk?(
                <Button disabled={true}  style={{background:'green', fontFamily:"'poppins',sans-serif", color:'white'}} _hover={{background:'none', border:'2px solid #055566', color:'#055566'}} > we have sent a email</Button>


            ):(
                
                setIsSubmitted(false)

            )
            
        ):(
            <Button onClick={handleForgotPasswordSubmit} style={{background:'#055566', fontFamily:"'poppins',sans-serif", color:'white'}} _hover={{background:'none', border:'2px solid #055566', color:'#055566'}} rightIcon={<ArrowForwardIcon/>}> proceed</Button>

        )
        }
        </div>


        </div>
    )

}
export default ForgotPasswordCard;