import React, { useState } from "react";
import "./buyerforms.css";
import { Button } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

function Buyerformone({ onDataUpdate, data, onFormChange }) {
  const [buyername, setbuyername] = useState(data.buyername || "");
  const [location, setlocation] = useState(data.location || "");
  const [industry, setindustry] = useState(data.industry || "");
  const [about, setabout] = useState(data.about || "");
  const toast = useToast();
  const updateData = () => {
    if (buyername && location && industry && about) {
      // Proceed to the next page only if all fields are filled
      onDataUpdate(2);
      onFormChange("buyerprofile", {
        buyername,
        location,
        industry,
        about,
      });
    } else {
      toast({
        title: "Please Fill all the Feilds",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };
  return (
    <div>
      <div className="horizontal-conatiner">
        <h1>Buyer Profile</h1>
      </div>
      <div className="buyerform-form">
        <div style={{ "max-width": "300px" }}>
          <form>
            <input
              type="text"
              id="buyername"
              name="buyername"
              placeholder="Name"
              onChange={(e) => setbuyername(e.target.value)}
              value={buyername}
              required={true}
            />
            <input
              type="text"
              id="location"
              name="location"
              placeholder="Location"
              onChange={(e) => setlocation(e.target.value)}
              value={location}
              required={true}
            />
            <input
              type="text"
              id="industry"
              name="industry"
              placeholder="Industry"
              onChange={(e) => setindustry(e.target.value)}
              value={industry}
              required
            />
          </form>
        </div>
        <div style={{ "max-width": "300px" }}>
          <textarea
            className="no-resize"
            rows="2"
            cols="40"
            placeholder="About "
            onChange={(e) => setabout(e.target.value)}
            value={about}
            required
          />
        </div>
        <Button
          onClick={updateData}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          NEXT
        </Button>
      </div>
    </div>
  );
}

export default Buyerformone;
