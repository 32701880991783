import React, { useState, useEffect } from "react";
import "./sellerform.css";
import { Button, Radio, RadioGroup, Stack } from "@chakra-ui/react";

function Sellerformthree({ onDataUpdate, data, step, onFormChange }) {
  const [value, setValue] = useState(data.value || "1");
  const [customercount, setcustomercount] = useState(data.customercount || "");
  const [userscount, setuserscount] = useState(data.userscount || "");
  const [competitorone, setcompetitorone] = useState(data.competitorone || "");
  const [competitortwo, setcompetitortwo] = useState(data.competitortwo || "");
  const backfunc = () => {
    onDataUpdate(2);
  };
  const updateData = () => {
    onDataUpdate(4);
    const updatedData = {
      value,
      customercount,
      userscount,
      competitorone,
      competitortwo,
    };
    onFormChange(step, updatedData);
  };

  return (
    <div>
      <div className="horizontal-conatiner">
        <h1>Company Information</h1>
      </div>
      <div className="sellerfrom-form">
        <div style={{ "max-width": "300px" }}>
          <div className="horizontal-conatiner">
            <h2>Do the company sell to consumers?</h2>
            <RadioGroup onChange={setValue} value={value}>
              <Radio
                value="1"
                borderColor={"#77898F"}
                _checked={{
                  bg: "#055566",
                  borderColor: "#055566",
                }}
              >
                <h2>YES</h2>
              </Radio>
              <Radio
                value="2"
                borderColor={"#77898F"}
                _checked={{
                  bg: "#055566",
                  borderColor: "#055566",
                }}
              >
                <h2>NO</h2>
              </Radio>
            </RadioGroup>
          </div>
          <input
            type="text"
            id="customercount"
            name="customercount"
            placeholder="Customers Count"
            onChange={(e) => setcustomercount(e.target.value)}
            value={customercount}
            disabled={value == 2 && true}
            required
          />
          <input
            type="text"
            id="userscount"
            name="userscount"
            placeholder="User Count"
            onChange={(e) => setuserscount(e.target.value)}
            value={userscount}
            disabled={value == 2 && true}
            required
          />
        </div>
        <div className="vertical-line"></div>
        <div style={{ "max-width": "300px" }}>
          <div className="horizontal-conatiner">
            <h2>Mention your competitors name?</h2>
          </div>

          <input
            type="text"
            id="competitorone"
            name="competitorone"
            placeholder="competitor 1"
            onChange={(e) => setcompetitorone(e.target.value)}
            value={competitorone}
            required
          />
          <input
            type="text"
            id="competitortwo"
            name="competitortwo"
            placeholder="competitor two"
            onChange={(e) => setcompetitortwo(e.target.value)}
            value={competitortwo}
            required
          />
        </div>
        <Button
          onClick={updateData}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          NEXT
        </Button>
        <Button
          style={{ position: "absolute", left: "0" }}
          onClick={backfunc}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          BACK
        </Button>
      </div>
    </div>
  );
}

export default Sellerformthree;
