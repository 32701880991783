import React, { useState, useEffect } from "react";
import "./sellerform.css";
import { Button } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
function Sellerformone({ onDataUpdate, data, step, onFormChange }) {
  const [compname, setcompname] = useState(data.compname || "");
  const [location, setlocation] = useState(data.location || "");
  const [teamsize, setteamsize] = useState(data.teamsize || "");
  const [category, setcategory] = useState(data.category || "");
  const [description, setdescription] = useState(data.description || "");
  const toast = useToast();
  const updateData = () => {
    if (compname && location && teamsize && category && description) {
      // Proceed to the next page only if all fields are filled
      onDataUpdate(2);
      onFormChange("sellerProfile", {
        compname,
        location,
        teamsize,
        category,
        description,
      });
    } else {
      toast({
        title: "Please Fill all the Feilds",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  return (
    <div>
      <div className="horizontal-conatiner">
        <h1>Seller Profile</h1>
      </div>
      <div className="sellerfrom-form">
        <div style={{ "max-width": "300px" }}>
          <form>
            <input
              type="text"
              id="compname"
              name="compname"
              placeholder="Company Name"
              onChange={(e) => setcompname(e.target.value)}
              value={compname}
              required={true}
            />
            <input
              type="text"
              id="location"
              name="location"
              placeholder="Location"
              onChange={(e) => setlocation(e.target.value)}
              value={location}
              required
            />
            <input
              type="text"
              id="teamsize"
              name="teamsize"
              placeholder="Team size"
              onChange={(e) => setteamsize(e.target.value)}
              value={teamsize}
              required
            />
          </form>
        </div>
        <div style={{ "max-width": "300px" }}>
          <div>
            <select
              type="text"
              id="category"
              name="category"
              placeholder="Category"
              onChange={(e) => setcategory(e.target.value)}
              value={category}
              required
            >
              <option value="">Category</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
          </div>
          <textarea
            rows="2"
            cols="40"
            placeholder="Description"
            onChange={(e) => setdescription(e.target.value)}
            value={description}
            required
          />
        </div>
        <Button
          onClick={updateData}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          NEXT
        </Button>
      </div>
    </div>
  );
}

export default Sellerformone;
