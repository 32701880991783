import {
  Box,
  Container,
  Button,
  Text,
  Flex,
  FormControl,
  Input,
  Stack,
  Link,
  InputRightElement,
  InputGroup,
  Icon,
  chakra,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { SiLinkedin } from "react-icons/si";

import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { styled } from "styled-components";
import "./stylesheet/login.css";

function Login({ switchToSignup }) {
  const toast = useToast();
  const history = useHistory()
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const showpass = () => setShow(!show);
  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials:'include',
      });

      if (response.ok) {
        // Login successful
       
        console.log('Login successful');
        const data =  await response.json()
        toast({
          title: "Signup sucess",
         
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        console.log(data)
        if(data.role){
          history.push('/test')
        }else{
          history.push('/acctype')
        }
        
      } else {
        // Login failed
        toast({
          title: "login failed",
         
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        console.log('Login failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  const Signuphandle = () => {
    switchToSignup();
  };
  const submitHandler = async () => {
    window.open("http://localhost:5000/api/auth/google", "_self");
  };

  return (
    <>
      <div className="login-box">
        <text>Welcome Back!</text>

        <div className="Oauth-box">
          <Oauthbtn
            variant="outline"
            leftIcon={<FcGoogle />}
            onClick={submitHandler}
          >
            Sign in with Google
          </Oauthbtn>
          <Oauthbtn
            variant={"outline"}
            leftIcon={<SiLinkedin color="#0A66C2" />}
          >
            Sign in with LinkedIn{" "}
          </Oauthbtn>
        </div>
        <Text color="#055566" align="center">
          -OR-
        </Text>
        <Stack spacing={5}>
          <FormControl id="email">
            <Input
              focusBorderColor="#055566"
              variant="flushed"
              placeholder="Email address"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              borderBottomColor="#055566"
              _placeholder={{ opacity: 1, color: "#055566" }}
            />
          </FormControl>
          <InputGroup size="md">
            <Input
              variant="flushed"
              placeholder="Password"
              borderBottomColor="#055566"
              focusBorderColor="#055566"
              onChange={(e) => setPassword(e.target.value)}
              _placeholder={{ opacity: 1, color: "#055566" }}
              type={show ? "text" : "password"}
            />
            <InputRightElement width="4.5rem">
              <Button bg="transparent" variant="none" onClick={showpass}>
                {show ? (
                  <Icon as={AiFillEye} boxSize={6} color="#055566" />
                ) : (
                  <Icon as={AiFillEyeInvisible} boxSize={6} color="#055566" />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Stack spacing={10}>
            <Stack
              direction={{ base: "column", sm: "row" }}
              align={"start"}
              justify={"space-between"}
            >
              <Link color={"#055566"} onClick={()=>{history.push("/user/forgotpassword")}} >Forgot password?</Link>
            </Stack>
            <Button
              bg={"#055566"}
              color={"white"}
              _hover={{
                bg: "#1B6879",
              }}
              onClick={handleLoginSubmit}
              
            >
              Sign in
            </Button>
            <Text color="#055566" style={{ textAlign: "left" }} mt="-10px">
              New User?{" "}
              <Link color={"black"} onClick={Signuphandle}>
                SignUp
              </Link>
            </Text>
          </Stack>
        </Stack>
      </div>
    </>
  );
}

//Writing the css.
const Oauthbtn = chakra(Button, {
  baseStyle: {
    borderColor: "#055566",
    width: "185px",
    color: "#055566",
    variant: "outline",
    _hover: {
      color: "#fff",
      bg: "#055566",
    },
  },
});

// const Oauthbtn = styled(Button)`
//   width: 185px;
//   border-color: #055566;
//   color: #055566;

//   &:hover {
//     color: #fff;
//     background-color: #055566;
//   }
// `;
// fontSize="4xl"
//           paddingBottom="30px"
//           color="#055566"
//           style={{ textAlign: "left" }}
// paddingBottom="50px"
//           flexDirection={{ base: "column", md: "row" }}
//           justify="space-between"

export default Login;
