import React from "react";
import {
  Box,
  Container,
  Button,
  Text,
  Flex,
  FormControl,
  Input,
  Stack,
  Link,
  chakra,
  Center,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { FcGoogle } from "react-icons/fc";
import { SiLinkedin } from "react-icons/si";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


function Signup({ switchToSignin }) {
  const toast = useToast();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory()
  const   HandleSignupSubmit = async( event )=>{
    event.preventDefault();
    try{
      const response = await fetch('http://localhost:5000/register',{
        method:'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name,email, password }),
        credentials:'include',

      })
      if (response.ok){
        toast({
          title: "Signup sucess",
         
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        history.go(0)
        console.log("signin success")
        
      }
      else if(response.status == 409){
        toast({
          title: "User already exists",
         
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
       


      }
      
      else{
        toast({
          title: "Error Occured!",
          description: "credentials failed to match",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    }catch(e){
      toast({
        title: "Oops something got stuck!",
        description: e,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      console.log('error', e)
    }

  }
  const Loginhandle = () => {
    switchToSignin();
  };

  return (
    <div className="login-box">
      <text>Create Account</text>
      <div className="Oauth-box">
        <Oauthbtn variant="outline" leftIcon={<FcGoogle />}>
          SignUp with Google
        </Oauthbtn>
        <Oauthbtn variant={"outline"} leftIcon={<SiLinkedin color="#0A66C2" />}>
          SignUp with LinkedIn
        </Oauthbtn>
      </div>
      <Text color="#055566" align="center">
        -OR-
      </Text>
      <Stack spacing={5}>
      <FormControl id="name">
          <Input
            focusBorderColor="#055566"
            variant="flushed"
            placeholder="name"
            type="text"
            borderBottomColor="#055566"
            onChange={(e) => setName(e.target.value)}
            _placeholder={{ opacity: 1, color: "#055566" }}
          />
        </FormControl>
        <FormControl id="email">
          <Input
            focusBorderColor="#055566"
            variant="flushed"
            placeholder="Email address"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            borderBottomColor="#055566"
            _placeholder={{ opacity: 1, color: "#055566" }}
          />
        </FormControl>
        <FormControl id="password">
          <Input
            focusBorderColor="#055566"
            variant="flushed"
            placeholder="Password"
            type="text"
            borderBottomColor="#055566"
            _placeholder={{ opacity: 1, color: "#055566" }}
          />
        </FormControl>
        <FormControl id="confirmpassword">
          <Input
            focusBorderColor="#055566"
            variant="flushed"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Confirm password"
            type="password"
            borderBottomColor="#055566"
            _placeholder={{ opacity: 1, color: "#055566" }}
          />
        </FormControl>
        <Stack spacing={10}>
          <Button
            m="30px 0px 0px 0px"
            bg={"#055566"}
            color={"white"}
            onClick={HandleSignupSubmit}
            _hover={{
              bg: "#1B6879",
            }}
          >
            Sign Up
          </Button>

          <Text color="#055566" style={{ textAlign: "left" }} mt="-10px">
            Already have an account?{"     "}
            <Link color={"black"} onClick={Loginhandle}>
              {" "}
              Signin
            </Link>
          </Text>
        </Stack>
      </Stack>
    </div>
  );
}

//Writing the css.
const Oauthbtn = chakra(Button, {
  baseStyle: {
    borderColor: "#055566",
    width: "185px",
    color: "#055566",
    variant: "outline",
    _hover: {
      color: "#fff",
      bg: "#055566",
    },
  },
});

export default Signup;
