import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import Signup from "../../components/Signup";
import Login from "../../components/Login";
import "./authtest.css";

function Authtest() {
  const [active, setActive] = useState("signin");
  const [disableAnimation, setDisableAnimation] = useState(false);

  const updateAnimationStatus = () => {
    const screenWidth = window.innerWidth;
    setDisableAnimation(screenWidth <= 750); // Disable animation for screen size 768px and below
  };

  useEffect(() => {
    const handleResize = () => {
      // Get the current window width
      const windowWidth = window.innerWidth;

      // Define your breakpoints
      const breakpoint1 = 1050;
      const breakpoint2 = 750;

      // Check if the window width crosses the breakpoints
      if (
        (windowWidth > breakpoint1 && prevWindowWidth <= breakpoint1) ||
        (windowWidth > breakpoint2 && prevWindowWidth <= breakpoint2)
      ) {
        // Reload the website when the screen size crosses the breakpoints
        window.location.reload();
      }

      // Update the previous window width
      prevWindowWidth = windowWidth;

      // Call your existing function
      updateAnimationStatus();
    };

    // Initialize previous window width
    let prevWindowWidth = window.innerWidth;

    // Attach the initial event listener
    window.addEventListener("resize", handleResize);

    updateAnimationStatus();
    window.addEventListener("resize", updateAnimationStatus);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateAnimationStatus);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const switchtosignup = () => {
    setActive("signup");
  };

  const switchtosignin = () => {
    setActive("signin");
  };

  return (
    <div>
      <div className="logo">
        <img src={require("../assets/logo.png")} />
      </div>
      <div className="container">
        <motion.div
          className="formcontainer"
          animate={!disableAnimation ? active : "initial"}
          variants={Formvariants}
          transition={{ duration: 0.65 }}
        >
          <motion.div
            className="imge"
            animate={!disableAnimation ? active : "initial"}
            variants={Imgevariants}
            transition={{ duration: 0.65 }}
          >
            <img src={require("../assets/authimage.png")} />
          </motion.div>
          {active === "signin" && <Login switchToSignup={switchtosignup} />}
          {active === "signup" && <Signup switchToSignin={switchtosignin} />}
        </motion.div>
      </div>
    </div>
  );
}

const Imgevariants = {
  signin: {
    right: null,
    left: 0,
    marginLeft: "-300px",
  },
  signup: {
    right: 0,
    left: null,
    marginRight: "-300px",
  },
};

const Formvariants = {
  signin: {
    left: null,
    right: 0, // Move to right, outside the window
    opacity: 1,
    variant: "signin",
    borderRadius: "80px 0px 0px 80px",
  },
  signup: {
    right: null,
    left: 0, // Move to the default position (left)
    opacity: 1, // Make it visible
    variant: "signup",
    borderRadius: "0px 80px 80px 0px",
  },
};

export default Authtest;
