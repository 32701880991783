import React, { useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import "./listcard.css";
import dollar from "../assets/dollar.svg";
import date from "../assets/date.svg";
import loc from "../assets/location.svg";
import customers from "../assets/customers.svg";
import bustype from "../assets/businesstype.svg";
import heart from "../assets/heart.svg";
import heartfill from "../assets/heart-fill.svg";
import { Button } from "@chakra-ui/react";
import { BiUpvote } from "react-icons/bi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Listcard = ({
  id,
  uname,
  description,
  location,
  businesstype,
  usertype,
  askprice,
}) => {
  const [heartval, setheartval] = useState(false);
  const router = useHistory();
  const wishlistadd = () => {
    if (heartval) {
      setheartval(false);
    } else {
      setheartval(true);
    }
  };
  const truncateDescription = (description) => {
    if (description.length <= 480) {
      return description;
    } else {
      const truncatedText = description.substring(0, 480);
      return `${truncatedText}...(view more)`;
    }
  };
  const busvalid = (businesstype) => {
    if (businesstype == "Not Available") {
      return "NA";
    } else {
      return businesstype;
    }
  };
  const handleClick = () => {
    alert("Add Link");
  };
  return (
    <div className="listing-card">
      <div className="box1">
        {" "}
        <img src={require("../assets/listing-logo.png")} />
      </div>
      <div className="box2" onClick={handleClick} style={{ cursor: "pointer" }}>
        <h1>{uname}</h1>
      </div>
      <div className="box3">
        <div style={{ width: "110px" }}>
          <p className="feature-head">LOCATION</p>
          <p className="icon-text">
            <img src={loc} width={"20%"} />
            {location}
          </p>
        </div>
        <div style={{ width: "110px" }}>
          <p className="feature-head">BUSINESS TYPE</p>
          <p className="icon-text">
            <img src={bustype} width={"20%"} />
            {busvalid(businesstype)}
          </p>
        </div>
        <div style={{ width: "110px" }}>
          <p className="feature-head">USER TYPE</p>
          <p className="icon-text">
            <img src={customers} width={"20%"} />
            {usertype}
          </p>
        </div>
      </div>
      <div className="box4">
        <h1>{askprice ? `$${askprice}K` : ""}</h1>
      </div>
      <div className="box5">
        <p>{truncateDescription(description)}</p>
        <div class="vert-list"></div>
      </div>
      <div className="box6">
        <Button
          onClick={handleClick}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          VIEW LISTING
        </Button>
      </div>
      <div className="box7">
        {/* <BiUpvote style={{ fontSize: "25px" }} /> UPVOTE */}
        <a onClick={wishlistadd} target="_blank" rel="noopener noreferrer">
          <img src={heartval ? heartfill : heart} />
        </a>
        Wishlist
      </div>
    </div>
  );
};

export default Listcard;
