import React, { useEffect } from "react";
import "./buyerform.css";

import Buyerformone from "../../components/buyerforms/Buyerformone.js";
import Buyerformtwo from "../../components/buyerforms/Buyerformtwo.js";
import { useState } from "react";
import AnimatedSVGT from "../../components/AnimatedSVGT";
function Buyerform() {
  const [position, setposition] = useState(1);
  const [formData, setFormData] = useState({
    buyerprofile: {
      buyername: "",
      location: "",
      industry: "",
      category: "",
      about: "",
    },
    preferences: {
      businesstype: "",
      businessmodel: "",
      techstack: "",
      preferredrevenue: "",
      preferredlocation: "",
    },
  });
  const handleChildData = (data) => {
    setposition(data);
  };
  const handleFormChange = (step, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data,
    }));
  };
  return (
    <div className="sellerform">
      <div>Navbar</div>
      <div className="sellerform-content">
        <div className="sellerform-box">
          <div>
            {position == 1 && (
              <Buyerformone
                onDataUpdate={handleChildData}
                data={formData.buyerprofile}
                onFormChange={handleFormChange}
              />
            )}
            {position == 2 && (
              <Buyerformtwo
                onDataUpdate={handleChildData}
                data={formData.preferences}
                onFormChange={handleFormChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buyerform;
