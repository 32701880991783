import React from "react";
import "./sellerprofile.css";

function Sellerprofile() {
  return (
    <div className="acqprofile">
      <div className="ap-head">
        <div className="banner-box">
          <div className="ban-box">The image comes here</div>
        </div>
      </div>
      <div className="ap-content">
        <div className="ap-title">
          <div className="logo-container">
            <div className="logo-box">..</div>
          </div>
          <div className="ap-title-content">
            <h1>Ippo pay Inc.</h1>
            <div style={{ display: "flex" }}>
              <p>Chennai</p>
              <p>IN</p>
              <p>SHRE</p>
            </div>
          </div>
        </div>
        <div className="ap-about">About company</div>
        <div className="ap-acqpref">Acquirerir Preference</div>
      </div>
    </div>
  );
}

export default Sellerprofile;
