import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
function Sellerformfour({ onDataUpdate, data, step, onFormChange }) {
  const [language, setlanguage] = useState(data.language || "");
  const [framework, setframework] = useState(data.framework || "");
  const [database, setdatabase] = useState(data.database || "");
  const [serverproviders, setserverproviders] = useState(
    data.serverproviders || ""
  );

  const backfunc = () => {
    onDataUpdate(3);
  };
  const updateData = () => {
    const updatedData = {
      language,
      framework,
      database,
      serverproviders,
    };
    onFormChange(step, updatedData);
  };

  return (
    <div>
      <div className="horizontal-conatiner">
        <h1>Tell us the technology used</h1>
      </div>
      <div className="sellerfrom-form" style={{ justifyContent: "center" }}>
        <div style={{ "max-width": "300px" }}>
          <input
            type="text"
            id="language"
            name="language"
            placeholder="Language"
            style={{ margin: "1rem 0rem" }}
            onChange={(e) => setlanguage(e.target.value)}
            value={language}
            required
          />
          <input
            type="text"
            id="framework"
            name="framework"
            placeholder="Framework"
            style={{ margin: "1rem 0rem" }}
            onChange={(e) => setframework(e.target.value)}
            value={framework}
            required
          />
          <input
            type="text"
            id="database"
            name="database"
            placeholder="Database"
            style={{ margin: "1rem 0rem" }}
            onChange={(e) => setdatabase(e.target.value)}
            value={database}
            required
          />
          <input
            type="text"
            id="serverproviders"
            name="serverproviders"
            placeholder="Server Providers"
            style={{ margin: "1rem 0rem" }}
            onChange={(e) => setserverproviders(e.target.value)}
            value={serverproviders}
            required
          />
        </div>
        <Button
          onClick={updateData}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          SUBMIT
        </Button>
        <Button
          style={{ position: "absolute", left: "0" }}
          onClick={backfunc}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          BACK
        </Button>
      </div>
    </div>
  );
}

export default Sellerformfour;
