import React from "react";
import { useState, useEffect } from "react";
import { UserState } from "../../Context";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Assets~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import EmptySVG from "../../assets/wishlistEmpty.svg"
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~CHAKRA UI~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import { Image, Text } from "@chakra-ui/react";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~React Reusable components~~~~~~~~~~~~~~~~~~~~~~
import Simple from "../../components/Navbar";
import HandleException from "../../components/HandleExceptions";
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~Stylesheets~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import "./wishlistpage.css"

const Wishlist = ()=>{
  const router = useHistory()
    const {user,setUser } = UserState();
    useEffect(() => {
      const checkAuthenticity = ()=>{
        console.log("The user from wishlist page is : ", user)
        if(user){
          if(user.role!=="BUYER"){
              router.goBack()
          }
        }
        else{
          router.push('/login')
        }
      }

      checkAuthenticity()
        
        
        }, [user]);
    return(
        <>
        {
            user?(
              <Simple userName={user.displayName} isLandingpage={false} email={user.email} isLoggedin={true}avatarUrl={user.image}/>
            ):(
              <Simple userName="{user.displayName}" isLandingpage={true} isLoggedin={false}  avatarUrl={"'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"}/>
            )
            
            

          }
         <HandleException text="Its empty here"/>
          </>

    )

}

export default Wishlist