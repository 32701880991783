import { Link,Stack,FormControl,Input,InputGroup, InputRightElement, Button,Icon } from "@chakra-ui/react"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";


const heroSectionStyle = {
    display: 'flex',
    flexDirection: 'row', /* Default layout - row */
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundSize: 'cover',
  };

const ResetPassword = ()=>{
    const history = useHistory()
    let {id,token} = useParams()


    const [newPassword, setNewPassword] = useState('')
    const [retypePassword, setRetypePassword] = useState('')
    const [show, setShow] = useState(false);
    const showpass = () => setShow(!show);
    const handleSubmit = async ()=>{
        const response = await fetch(`http://localhost:5000/api/user/forgotpassword/${id}/${token}`,{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            body: JSON.stringify({newPassword})

        })
        if(response.ok){
            history.push('/login')
        }else{
            console.log("error")
        }
    }
    return(
        <>
        <div style={heroSectionStyle}>

         <Stack spacing={5}>
          <FormControl id="email">
            <Input
              focusBorderColor="#055566"
              variant="flushed"
              placeholder="New Password"
              type="text"
              onChange={(e) => setNewPassword(e.target.value)}
              borderBottomColor="#055566"
              _placeholder={{ opacity: 1, color: "#055566" }}
            />
            
            
          </FormControl>
          <InputGroup size="md">
            <Input
              variant="flushed"
              placeholder="Retype-Password"
              borderBottomColor="#055566"
              focusBorderColor="#055566"
              onChange={(e) => setRetypePassword(e.target.value)}
              _placeholder={{ opacity: 1, color: "#055566" }}
              type={show ? "text" : "password"}
            />
            
            <InputRightElement width="4.5rem">
              <Button bg="transparent" variant="none" onClick={showpass}>
                {show ? (
                  <Icon as={AiFillEye} boxSize={6} color="#055566" />
                ) : (
                  <Icon as={AiFillEyeInvisible} boxSize={6} color="#055566" />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Stack spacing={10}>
           
            <Button
              bg={"#055566"}
              color={"white"}
              _hover={{
                bg: "#1B6879",
              }}
              onClick={handleSubmit}
              
            >
              Submit
            </Button>
            
          </Stack>
        </Stack>
        </div>
        </>
    )
}
export default ResetPassword