import { useEffect, useState } from "react";
import axios from "axios";
import Profile from "../components/Profile";
import Simple from "../components/Navbar";

const ProfilePage = ()=>{
    const [user, setUser] = useState(null);
    useEffect(() => {
        const getUser = async () => {
          try {
            const config = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
              },
              withCredentials: true 
            };
            const borl = await axios.get("/api/auth/login/success", config);
            console.log("borl",borl)
            setUser(borl.data.user);
            
          } catch (error) {
            console.log(error)
           
          }
        };
        getUser()
        }, []);
    return(
        <>
        {
            user?(
              <Simple userName={user.displayName} isLandingpage={false} email={user.email} isLoggedin={true}avatarUrl={user.image}/>
            ):(
              <Simple userName="{user.displayName}" isLandingpage={false} isLoggedin={false}  avatarUrl={"'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'"}/>
            )

          }
          <Profile/>
        </>
    )
}
export default ProfilePage