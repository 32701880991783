import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    Button,
    StackDivider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoSearchSharp,
  } from 'react-icons/io5';
  import { ReactElement } from 'react';
  import '../stylesheet/herosection.css'
  import FeatureImg from '../assets/handshake.png'
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function HandshakeSection() {
    return (

      <section className='handshake-section'>
        <Container maxW={'container.xl'} py={12} padding={'2%'}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} padding={'2%'}>
       <div className='grid-section'>
          <Stack spacing={4}>
            
            <Heading color={"#FFFEFE"}>Bridging The Gap !</Heading>
            <Text color={'gray.500'} fontSize={'lg'}>
            People talk about connecting the dots. But we believe in building bridges. As your trusted concierge we have a variety of options for you to choose from.
            </Text>
            </Stack>
            <Stack>
         
         <Button
           
           size={'lg'}
           fontWeight={'bold'}
           width={'25%'}
           px={6}
           bg={'#045567'}
           borderRadius='2px'
           backgroundColor={'none'}
           border={'1px solid'}
           color={'white'}
           transition={'box-shadow 0.3s ease'}
           _hover={{ border:'1px solid', boxShadow:"0 0 10px rgba(0, 0, 0, 0.5);" }}>
           Listings
         </Button>
     
          </Stack>
        </div>
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={FeatureImg}
              objectFit={'cover'}
            />
          </Flex>
          
        
        </SimpleGrid>
        </Container>
        </section>
      
    );
  }