import { ReactNode } from 'react';
import { useMediaQuery } from "react-responsive";
import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
  Grid,
  GridItem,
  Image
} from '@chakra-ui/react';
import CounterImage1 from "../../assets/counter-img-one.png"
import CounterImage2 from "../../assets/counter-img-two.png"

export default function Section3() {  
  
  const isMobile = useMediaQuery({maxWidth:768})
  const isTablet = useMediaQuery({maxWidth:1024})
  return (
    
     
      <Container maxW={'8xl'}  position={'relative'} paddingTop={'5%'}>
        
          <Stack
            
            color={'#065666'}
            
            >
           

            <Grid
            h='600px'
            templateRows='repeat(2, 1fr)'
            templateColumns='repeat(3, 1fr)'
            gap={4}>
                
                
  <GridItem  colSpan={isMobile?3:2} bg='#B3D9E0' borderRadius={'10px'}  display="flex"
        justifyContent="center"
        flexDirection={'column'}
        alignItems="center"> <Box padding={'10'} width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}><Text fontSize={'15vh'} fontWeight={'700'}> 5000+</Text> <Text fontSize={'xl'}>Startups Identified</Text> </Box> </GridItem>
  <GridItem colSpan={1} bg='#B3D9E0' borderRadius={'10px'} display={isMobile?"none":"flex"} flexDirection="column" justifyContent='center' alignItems="center">
    <Box boxSize="2xs" >
      <Image src={CounterImage1}/>
    </Box>
  </GridItem>
  <GridItem colSpan={1} bg='#B3D9E0' borderRadius={'10px'} display={isMobile?"none":"flex"} flexDirection="column" justifyContent='center' alignItems="center">
  <Box boxSize="2xs" >
      <Image src={CounterImage2}/>
    </Box>
    </GridItem>
  <GridItem colSpan={isMobile?3:2} bg='#B3D9E0' borderRadius={'10px'} display="flex"
        justifyContent="center"
        alignItems="center"><Box padding={'10'} width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}><Text fontSize={'8xl'} fontWeight={'700'}> 5+</Text> <Text fontSize={'xl'}>Sucessful deals</Text> </Box></GridItem>
</Grid>
               
          </Stack>
          <Flex flex={1} />
        
      </Container>
    
  );
}

const StatsText = ({ children }: { children: ReactNode }) => (
  <Text as={'span'} fontWeight={700} color={'#065666'}>
    {children}
  </Text>
);

const stats = [
  {
    title: '10+',
    content: (
      <>
        <StatsText>Software modules</StatsText> 
      </>
    ),
  },
  {
    title: '24/7',
    content: (
      <>
        <StatsText>Analytics</StatsText> enabled right in your dashboard without
        history limitations
      </>
    ),
  }]
  const secondStats =[
  {
    title: '13%',
    content: (
      <>
        <StatsText>Farms</StatsText> in North America has chosen NewLife™ as
        their management solution
      </>
    ),
  },
  {
    title: '250M+',
    content: (
      <>
        <StatsText>Plants</StatsText> currently connected and monitored by the
        NewLife™ software
      </>
    ),
  }]
