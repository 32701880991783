import React, { useState } from "react";
import "./buyerforms.css";
import { Button } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
function Buyerformtwo({ onDataUpdate, data, onFormChange }) {
  const toast = useToast();
  const [businesstype, setbusinesstype] = useState(data.businesstype || "");
  const [businessmodel, setbusinessmodel] = useState(data.businessmodel || "");
  const [techstack, settechstack] = useState(data.techstack || "");
  const [teamsize, setteamsize] = useState(data.teamsize || "");
  const [preferredrevenue, setpreferredrevenue] = useState(
    data.preferredrevenue || ""
  );
  const [preferredlocation, setpreferredlocation] = useState(
    data.preferredlocation || ""
  );
  const updateData = () => {
    onDataUpdate(1);
  };
  return (
    <div>
      <div className="horizontal-conatiner">
        <h1>Preferences</h1>
      </div>
      <div className="buyerform-form">
        <div style={{ "max-width": "300px" }}>
          <form>
            <div>
              <select
                type="text"
                id="businesstype"
                name="businesstype"
                onChange={(e) => setbusinesstype(e.target.value)}
                value={businesstype}
                required
              >
                <option value="">Business Type</option>
                <option value="option1">B2B</option>
                <option value="option2">B2C</option>
                <option value="option3">Enterprise</option>
              </select>
            </div>
            <div>
              <select
                type="text"
                id="businessmodel"
                name="businessmodel"
                placeholder="Category"
                onChange={(e) => setbusinessmodel(e.target.value)}
                value={businessmodel}
                required
              >
                <option value="">Preferred Business Model</option>
                <option value="option1">SAAS</option>
                <option value="option2">License</option>
                <option value="option3">Free</option>
              </select>
            </div>
            <div>
              <select
                type="text"
                id="techstack"
                name="techstack"
                onChange={(e) => settechstack(e.target.value)}
                value={techstack}
                required
              >
                <option value="">Preferred Tech Stack</option>
                <option value="option1">Python</option>
                <option value="option2">Java</option>
                <option value="option3">Rust</option>
              </select>
            </div>
          </form>
        </div>
        <div style={{ "max-width": "300px" }}>
          <div>
            <select
              type="text"
              id="teamsize"
              name="teamsize"
              onChange={(e) => setteamsize(e.target.value)}
              value={teamsize}
              required
            >
              <option value="">Preferred Team Size</option>
              <option value="option1">1-50</option>
              <option value="option2">50-100</option>
              <option value="option3">More than 100</option>
            </select>
          </div>
          <input
            type="text"
            id="preferredrevenue"
            name="preferredrevenue"
            placeholder="Preferred Revenue"
            onChange={(e) => setpreferredrevenue(e.target.value)}
            value={preferredrevenue}
            required={true}
          />
          <input
            type="text"
            id="preferredlocation"
            name="preferredlocation"
            placeholder="Preferred Location"
            onChange={(e) => setpreferredlocation(e.target.value)}
            value={preferredlocation}
            required={true}
          />
        </div>
        <Button
          // onClick={updateData}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          SUBMIT
        </Button>
        <Button
          style={{ position: "absolute", left: "0" }}
          onClick={updateData}
          _hover={{ background: "#003f4c" }}
          _active={{ background: "#002835" }}
        >
          BACK
        </Button>
      </div>
    </div>
  );
}

export default Buyerformtwo;
