import React, { useContext, useEffect, useState } from "react";
import "./listingpage.css";
import dollar from "../assets/dollar.svg";
import date from "../assets/date.svg";
import customers from "../assets/customers.svg";
import heart from "../assets/heart.svg";
import { Button } from "@chakra-ui/react";
import { BiUpvote } from "react-icons/bi";
import Login from "../../components/Login";
import { UserState } from "../../Context";
import axios from "axios";
import Listcard from "../listcard/Listcard";

function Listingpage() {
  const { user } = UserState();
  console.log(UserState());
  const [listings, setListings] = useState("");
  useEffect(() => {
    const fetchdata = async () => {
      const data = await axios.get("/all");
      // console.log("products >>>", data);
      setListings(data);
    };
    fetchdata();
  }, []);

  return (
    <div className="listings">
      {/* <div className="listings-herobar">
        <div className="listings-herobar-content">
          <div className="listings-herobar-heading">
            <h1>Top Rated Companies</h1>
            <p>
              Lorem Ipsum DOlor Sit Amet, Consectetur Adipiscng Elit. Sed
              Ullmascor Congue Eros.
            </p>
            <Button variant={"outline"} className="listings-herobar-btn">
              {" "}
              SEE MORE
            </Button>
          </div>
          <div className="listings-herobar-carousel"> Carousel Here</div>
        </div>
      </div> */}

      <div className="listings-heading-box">
        {user ? <h1>{user.email}</h1> : <h1>Our Listings</h1>}
      </div>
      {listings &&
        listings.data.data.map((listing) => (
          <Listcard
            id={listing.id}
            uname={listing.uniqueName}
            description={listing.description}
            location={listing.location}
            businesstype={listing.metrics.companyType}
            usertype={listing.metrics.customerType}
            askprice={listing.AskingPrice}
          />
        ))}
      {/* <Listcard />
      <Listcard />
      <Listcard /> */}
    </div>
  );
}

export default Listingpage;
