import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    Button
  } from '@chakra-ui/react';
  import { useMediaQuery } from "react-responsive";
  import '../stylesheet/herosection.css'
  import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoSearchSharp,
  } from 'react-icons/io5';
  import { ReactElement } from 'react';
  import FeatureImg from '../assets/section4.png'
import { color } from 'framer-motion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }
  
  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };
  
  export default function ImageSecond(props) {  
    const isMobile = useMediaQuery({maxWidth:768})
  const isTablet = useMediaQuery({maxWidth:1024})

    const history = useHistory()
    return (

      <section className='aim-section'>
        <Container maxW={'8xl'} py={12} padding={'2%'}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} padding={'2%'} style={isMobile?{display:'flex', flexDirection:'column-reverse'}:{}}>
          
       <div className='aim-grid-section'>
          <Stack spacing={4}>
            
            <Heading color={"#055566"}  fontSize={{ base: '3xl', sm: '5xl', lg: '6xl' }}>{props.head}</Heading>
            <Text color={'gray.500'} fontSize={'xl'}>
            {props.text}
            </Text>
          </Stack>
            
         
         <Button
           
           size={'lg'}
           fontWeight={'bold'}
           width={'fit-content'}
           px={6}
           bg={'#045567'}
           margin={'2%'}
           borderRadius='2px'
          
           color={'white'}
           onClick={props.handleClick}
           _hover={{ bg: 'none', border:'2px solid #045567', color:'#045567' }}>
           {props.buttonText}
         </Button>
     
          
        </div>
          <Flex flexDir={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'center', md: 'flex-start' }}>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={props.featureImg}
              objectFit={'cover'}
              padding={'10%'}
            />
          </Flex>
          
          
        </SimpleGrid>
        </Container>
        </section>
      
    );
  }